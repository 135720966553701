import { isObject } from 'lodash';
import { useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { IdValueType } from '../@types/common';
import { CauseDiagramNode, ConsequenceDiagramNode, ControlDiagramNode, DiagramConfiguration } from '../@types/diagram';
import { TransformedSimpleRecordDto } from '../api/@types/enhanced-v4-api.types';
import { useGetSimpleRecordQuery, useGetSimpleRecordsQuery } from '../api/enhanced/enhanced-v4-api';
import { useConfigurationContext } from '../context/configuration.context';
import { criticalFilterValues, effectiveFilterValues } from '../helpers/constants';
import { CriticalControlEnum, EffectiveControlEnum } from '../helpers/node-util';

/**
 * Hook for managing and constructing diagram data based on record information.
 *
 * This hook handles the fetching and processing of:
 * - Main scenario/MUE record
 * - Associated causes and their preventative controls
 * - Associated consequences and their mitigating controls
 *
 * The hook constructs a diagram configuration containing nodes and relationships
 * between different elements (causes, consequences, controls, etc.).
 *
 * @returns {Object} An object containing:
 *   - isFetching: boolean - Indicates if any data is currently being fetched
 *   - diagramData: DiagramConfiguration | undefined - The constructed diagram data structure
 *
 * @remarks
 * The hook relies on URL parameters 'records' or 'recordId' to identify the main record.
 * If no record ID is provided, it initializes an empty diagram in edit mode.
 *
 * @example
 * ```typescript
 * const { isFetching, diagramData } = useRecord();
 * ```
 */
const useRecord = () => {
  const diagramDataRef = useRef<DiagramConfiguration>();

  const { search } = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const hasRecordId = queryParams.has('records') || queryParams.has('recordId');

  const {
    main: {
      scenarioFieldId,
      hazardFieldId,
      causesReverseRecordLinkFieldId,
      consequencesReverseRecordLinkFieldId,
      mitigatingControlsRecordLinkFieldId,
      preventativeControlsRecordLinkFieldId,
    },
    causes: {
      formId: causesFormId,
      fieldId: causesCaptionFieldId,
      controlsReverseRecordLinkFieldId: causesControlsRecordLinkFieldId,
    },
    consequences: {
      formId: consequencesFormId,
      fieldId: consequencesCaptionFieldId,
      controlsReverseRecordLinkFieldId: consequencesControlsRecordLinkFieldId,
    },
    controls: {
      form: { workflowSteps: controlFormWorkflowSteps },
      formId: controlFormId,
      fieldId: controlCaptionFieldId,
      controlTypeFieldId,
      controlEffectivenessFieldId,
      criticalOrNonCriticalFieldId,
      effectiveOrNotEffectiveField,
      isGlobalFieldId: globalFieldKey,
      preventativeControls: { causesRecordLinkFieldId },
      mitigatingControls: { consequencesRecordLinkFieldId },
    },
  } = useConfigurationContext();

  const draftWorkflowStepLabel = useMemo(() => {
    return controlFormWorkflowSteps?.find((step) => step.draft)?.label ?? 'Draft';
  }, [controlFormWorkflowSteps]);

  // mue / risk scenario record
  const { data: mueRecord, isFetching: isMueFetching } = useGetSimpleRecordQuery(
    { id: Number(queryParams.get('records') ?? queryParams.get('recordId')), params: {} },
    { skip: !hasRecordId }
  );

  // causes
  const causesRecordIds = (mueRecord?.fields?.[causesReverseRecordLinkFieldId] as Array<number>) || [];

  const { data: causesRecords, isFetching: isCausesFetching } = useGetSimpleRecordsQuery(
    {
      formId: causesFormId,
      filter: `id:in:${causesRecordIds?.join(',')}`,
      pageSize: 50,
    },
    {
      skip: isMueFetching || !mueRecord || !causesRecordIds || causesRecordIds.length === 0,
    }
  );

  // consequences
  const consequencesRecordIds = (mueRecord?.fields?.[consequencesReverseRecordLinkFieldId] as Array<number>) || [];

  const { data: consequencesRecords, isFetching: isConsequencesFetching } = useGetSimpleRecordsQuery(
    {
      formId: consequencesFormId,
      filter: `id:in:${consequencesRecordIds?.join(',')}`,
      pageSize: 50,
    },
    {
      skip: isMueFetching || !mueRecord || !consequencesRecordIds || consequencesRecordIds.length === 0,
    }
  );

  // preventative controls
  const preventativeControlsRecordIds = mueRecord?.fields?.[preventativeControlsRecordLinkFieldId] as Array<string>;

  const { data: preventativeControlsRecords, isFetching: isPreventativeControlsFetching } = useGetSimpleRecordsQuery(
    {
      formId: controlFormId,
      filter: `id:in:${preventativeControlsRecordIds?.join(',')}`,
      pageSize: 200,
    },
    {
      skip: isMueFetching || !mueRecord || !preventativeControlsRecordIds || preventativeControlsRecordIds.length === 0,
    }
  );

  // mitigating controls
  const mitigatingControlsRecordIds = mueRecord?.fields?.[mitigatingControlsRecordLinkFieldId] as Array<string>;

  const { data: mitigatingControlsRecords, isFetching: isMitigatingControlsFetching } = useGetSimpleRecordsQuery(
    {
      formId: controlFormId,
      filter: `id:in:${mitigatingControlsRecordIds?.join(',')}`,
      pageSize: 200,
    },
    {
      skip: isMueFetching || !mueRecord || !mitigatingControlsRecordIds || mitigatingControlsRecordIds.length === 0,
    }
  );

  // construct the diagram data
  if (
    hasRecordId &&
    !isMueFetching &&
    !isCausesFetching &&
    !isConsequencesFetching &&
    !isPreventativeControlsFetching &&
    !isMitigatingControlsFetching &&
    !diagramDataRef.current &&
    mueRecord?.id
  ) {
    diagramDataRef.current = {
      mue: { id: crypto.randomUUID() },
      causes: [],
      consequences: [],
    };

    // mue
    diagramDataRef.current.mue = {
      ...diagramDataRef.current.mue,
      recordId: mueRecord.id,
      linkUrl: mueRecord.linkUrl,
      label: mueRecord?.fields?.[scenarioFieldId] as string,
    };

    // hazard
    diagramDataRef.current.hazard = {
      id: crypto.randomUUID(),
      label: mueRecord?.fields?.[hazardFieldId] as string,
    };

    // causes and preventative controls
    const linkedPreventativeControlRecords =
      preventativeControlsRecords
        ?.filter((record) => record.fields)
        .map((controlRecord) => {
          // filter the preventative control record IDs which are also linked to the mue record (present in the causes record link field)
          let controlCausesRecordIds = (controlRecord.fields![causesRecordLinkFieldId] as Array<string>) ?? [];

          controlCausesRecordIds = controlCausesRecordIds.filter((id) => causesRecordIds.includes(Number(id)));

          if (controlCausesRecordIds.length > 0) {
            return controlRecord;
          }
        })
        .filter((controlRecord): controlRecord is TransformedSimpleRecordDto => Boolean(controlRecord)) ?? [];

    diagramDataRef.current.causes =
      causesRecordIds
        ?.map((recordId) => {
          // The 'causesRecords' array does not come in the order based on the specified query id list, so we need to ensure the field order is respected
          const causeRecord = causesRecords?.find((record) => record.id === recordId);
          if (causeRecord && causeRecord.fields) {
            // the list defines the node order in the diagram
            const preventativeControlsIdList = causeRecord.fields[causesControlsRecordLinkFieldId] as Array<number>;

            const controls: Array<ControlDiagramNode> =
              preventativeControlsIdList
                ?.map((controlId) => {
                  const controlRecord = linkedPreventativeControlRecords.find((record) => record.id === controlId);
                  if (controlRecord) {
                    const isGlobal = Boolean(controlRecord.fields![globalFieldKey]);
                    const isDraft = controlRecord.status === draftWorkflowStepLabel;

                    const criticalOrNonCritical = resolveCriticalControlType(
                      controlRecord.fields![criticalOrNonCriticalFieldId]
                    );

                    const effectiveOrNotEffective = resolveEffectiveControlType(
                      (controlRecord as Record<string, unknown>)[effectiveOrNotEffectiveField]
                    );

                    return {
                      id: crypto.randomUUID(),
                      recordId: controlRecord!.id,
                      parentRecordId: causeRecord.id,
                      linkUrl: controlRecord!.linkUrl,
                      label: controlRecord!.fields?.[controlCaptionFieldId] as string,
                      controlType: controlRecord!.fields?.[controlTypeFieldId] as IdValueType,
                      controlEffectiveness: controlRecord!.fields?.[controlEffectivenessFieldId] as IdValueType,
                      global: isGlobal,
                      draft: isDraft,
                      criticalControlType: criticalOrNonCritical,
                      effectiveControlType: effectiveOrNotEffective,
                    } as ControlDiagramNode;
                  }
                })
                .filter((record): record is ControlDiagramNode => Boolean(record)) ?? [];

            return {
              id: crypto.randomUUID(),
              recordId: causeRecord.id,
              linkUrl: causeRecord.linkUrl,
              label: causeRecord.fields[causesCaptionFieldId] as string,
              controls,
            } as CauseDiagramNode;
          }
        })
        .filter((cause): cause is CauseDiagramNode => Boolean(cause)) ?? [];

    // consequences and mitigating controls
    const linkedMitigatingControlRecords =
      mitigatingControlsRecords
        ?.filter((record) => record.fields)
        .map((controlRecord) => {
          // filter the mitigating control record IDs which are also linked to the mue record (present in the consequences record link field)
          let controlConsequencesRecordIds =
            (controlRecord.fields![consequencesRecordLinkFieldId] as Array<string>) ?? [];

          controlConsequencesRecordIds = controlConsequencesRecordIds.filter((id) =>
            consequencesRecordIds.includes(Number(id))
          );

          if (controlConsequencesRecordIds.length > 0) {
            return controlRecord;
          }
        })
        .filter((controlRecord): controlRecord is TransformedSimpleRecordDto => Boolean(controlRecord)) ?? [];

    diagramDataRef.current.consequences =
      consequencesRecordIds
        ?.map((recordId) => {
          // The 'consequencesRecords' array does not come in the order based on the specified query id list, so we need to ensure the field order is respected
          const consequenceRecord = consequencesRecords?.find((record) => record.id === recordId);
          if (consequenceRecord && consequenceRecord.fields) {
            // the list defines the node order in the diagram
            const mitigatingControlsIdList = consequenceRecord.fields[
              consequencesControlsRecordLinkFieldId
            ] as Array<number>;

            const controls: Array<ControlDiagramNode> =
              mitigatingControlsIdList
                ?.map((controlId) => {
                  const controlRecord = linkedMitigatingControlRecords.find((record) => record.id === controlId);
                  if (controlRecord) {
                    const controlConsequencesRecordIds =
                      (controlRecord.fields![consequencesRecordLinkFieldId] as Array<string>) ?? [];

                    if (controlConsequencesRecordIds.includes(String(consequenceRecord.id))) {
                      const isGlobal = Boolean(controlRecord.fields![globalFieldKey]);
                      const isDraft = controlRecord.status === draftWorkflowStepLabel;

                      const criticalOrNonCritical = resolveCriticalControlType(
                        controlRecord.fields![criticalOrNonCriticalFieldId]
                      );

                      const effectiveOrNotEffective = resolveEffectiveControlType(
                        (controlRecord as Record<string, unknown>)[effectiveOrNotEffectiveField]
                      );

                      return {
                        id: crypto.randomUUID(),
                        recordId: controlRecord!.id,
                        parentRecordId: consequenceRecord.id,
                        linkUrl: controlRecord!.linkUrl,
                        label: controlRecord!.fields?.[controlCaptionFieldId] as string,
                        controlType: controlRecord!.fields?.[controlTypeFieldId] as IdValueType,
                        controlEffectiveness: controlRecord!.fields?.[controlEffectivenessFieldId] as IdValueType,
                        global: isGlobal,
                        draft: isDraft,
                        criticalControlType: criticalOrNonCritical,
                        effectiveControlType: effectiveOrNotEffective,
                      } as ControlDiagramNode;
                    }
                  }
                })
                .filter((record): record is ControlDiagramNode => Boolean(record)) ?? [];

            return {
              id: crypto.randomUUID(),
              recordId: consequenceRecord.id,
              linkUrl: consequenceRecord.linkUrl,
              label: consequenceRecord.fields[consequencesCaptionFieldId] as string,
              controls,
            } as ConsequenceDiagramNode;
          }
        })
        .filter((consequence): consequence is ConsequenceDiagramNode => Boolean(consequence)) ?? [];
  } else if (!hasRecordId) {
    diagramDataRef.current = {
      mue: { id: crypto.randomUUID(), editMode: true },
      causes: [],
      consequences: [],
    };
  }

  const isFetching =
    isMueFetching ||
    isCausesFetching ||
    isConsequencesFetching ||
    isPreventativeControlsFetching ||
    isMitigatingControlsFetching;

  return {
    isFetching,
    diagramData: diagramDataRef.current,
  };
};

export default useRecord;

// util
export const resolveCriticalControlType = (fieldValue?: unknown) => {
  if (fieldValue && isObject(fieldValue) && 'value' in fieldValue) {
    if (fieldValue.value === criticalFilterValues.truthy) {
      return CriticalControlEnum.CRITICAL;
    } else if (fieldValue.value === criticalFilterValues.falsy) {
      return CriticalControlEnum.NON_CRITICAL;
    }
  }
  return undefined;
};

export const resolveEffectiveControlType = (fieldValue?: unknown) => {
  if (fieldValue) {
    if (fieldValue === effectiveFilterValues.truthy) {
      return EffectiveControlEnum.EFFECTIVE;
    } else if (fieldValue === effectiveFilterValues.falsy) {
      return EffectiveControlEnum.NON_EFFECTIVE;
    }
  }
  return undefined;
};
