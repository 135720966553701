import { memo } from 'react';
import { IconProps } from '../../../../@types/common';
import { cn } from '../../../../helpers/util';

/**
 * A circular arrow up icon component.
 *
 * @component
 * @param {Object} props - The component props
 * @param {number} [props.width=20] - The width of the icon in pixels
 * @param {number} [props.height=20] - The height of the icon in pixels
 * @param {string} [props.className] - Additional CSS class names to apply to the icon
 * @returns {JSX.Element} An SVG icon of an upward-pointing arrow in a circle
 *
 * @example
 * ```tsx
 * <ArrowUpIcon width={24} height={24} className="custom-class" />
 * ```
 */
function ArrowUpIcon({ width = 24, height = 24, className }: IconProps): JSX.Element {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" className={cn(className, 'bt-fill-current')}>
      <path d="M12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2ZM12 20C16.42 20 20 16.42 20 12C20 7.58 16.42 4 12 4C7.58 4 4 7.58 4 12C4 16.42 7.58 20 12 20ZM13 12V16H11V12H8L12 8L16 12H13Z"></path>
    </svg>
  );
}

export default memo(ArrowUpIcon);
