import { Node, NodeProps } from '@xyflow/react';
import { useMemo } from 'react';
import { cn } from '../../../helpers/util';
import { useAppSelector } from '../../../redux/hooks';
import { selectCausesLength, selectControlsLengthByCauseId, selectMoving } from '../../../redux/slices/diagram';
import BaseNode, { BaseNodeData } from './base-node.component';
import { HandlesProps } from './common/handles.component';
import VerticalMoveControl, { VerticalMoveDirection } from './common/vertical-move-control.component';

type CauseNodeData = BaseNodeData;
export type CauseNodeType = Node<CauseNodeData>;
type CauseNodeProps = NodeProps<CauseNodeType>;

const className = 'bt-bg-causes-box-bg bt-border-causes-box-border bt-text-causes-box-fg';
const iconClassName = 'bt-text-causes-box-bg bt-stroke-causes-box-fg';
const handles: HandlesProps = { rightHandleId: 'right', rightHandleType: 'source' };
const staticData = { className, iconClassName, handles };

/**
 * A component that represents a cause node in a flow diagram.
 * Extends the base node component with cause-specific functionality.
 *
 * @component
 * @param {CauseNodeProps} props - The properties for the cause node component
 * @returns {JSX.Element} A rendered cause node component
 *
 * @example
 * ```tsx
 * <CauseNode
 *   id="cause-1"
 *   data={{ label: "Root Cause" }}
 *   position={{ x: 100, y: 100 }}
 * />
 * ```
 */
const CauseNode = (props: CauseNodeProps): JSX.Element => {
  const causesLength = useAppSelector(selectCausesLength);
  const controlsLength = useAppSelector((state) => selectControlsLengthByCauseId(state, props.data.recordId!));

  const moving = useAppSelector(selectMoving);
  const { rowIndex } = props.data;

  const canMoveUp = useMemo(() => (rowIndex !== undefined ? rowIndex > 0 : false), [rowIndex]);

  const canMoveDown = useMemo(
    () => (rowIndex !== undefined ? rowIndex < causesLength - 1 : false),
    [rowIndex, causesLength]
  );

  const hasControls = controlsLength > 0;

  const data = useMemo(() => {
    const className = cn(staticData.className, { 'bt-animate-wobble': moving && (canMoveUp || canMoveDown) });
    return { ...props.data, ...staticData, className, showUnlinkActionIcon: !props.data.recordId || !hasControls };
  }, [props.data, canMoveUp, canMoveDown, moving]);

  return (
    <div className="bt-group bt-flex bt-items-center">
      <VerticalMoveControl
        id={props.id}
        type="cause-node"
        index={data.rowIndex!}
        title="Move Up"
        direction={VerticalMoveDirection.UP}
        disabled={!moving || !canMoveUp}
      />

      <BaseNode {...props} data={data} />

      <VerticalMoveControl
        id={props.id}
        type="cause-node"
        index={data.rowIndex!}
        title="Move Down"
        direction={VerticalMoveDirection.DOWN}
        disabled={!moving || !canMoveDown}
      />
    </div>
  );
};

export default CauseNode;
