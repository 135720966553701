import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { useDeleteBowtieMutation } from '../api/enhanced/enhanced-bowtie-api';
import ConfirmActionToast from '../components/common/confirm-action-toast.component';

const useDeleteDiagram = (diagramId: string | null) => {
  const [loading, setLoading] = useState(false);
  const [deleteBowtie] = useDeleteBowtieMutation();

  const handleDelete = useCallback(async () => {
    if (!diagramId) return;

    setLoading(true);

    const toastId = toast.loading('Deleting the diagram...');

    try {
      const result = await deleteBowtie({ bowtieId: diagramId }).unwrap();

      if (result) {
        toast.update(toastId, {
          render: 'Diagram deleted successfully',
          type: 'success',
          isLoading: false,
          autoClose: 2000,
        });

        setTimeout(() => window.location.replace(`${window.location.origin}${window.location.pathname}`), 2000);
      } else {
        toast.update(toastId, {
          render: 'Unable to delete the diagram',
          type: 'error',
          isLoading: false,
          autoClose: 2000,
        });
      }
    } catch (_) {
      toast.update(toastId, {
        render: 'Unable to delete the diagram',
        type: 'error',
        isLoading: false,
        autoClose: 2000,
      });
    } finally {
      setLoading(false);
    }
  }, [diagramId]);

  const handleClick = useCallback(() => {
    toast(
      (props) =>
        React.createElement(ConfirmActionToast, {
          ...props,
          message: 'Are you sure you want to delete the diagram?',
          onConfirmActionClick: handleDelete,
          confirmActionLabel: 'Delete',
        }),
      {
        autoClose: 10_000, // auto close after 10 seconds
      }
    );
  }, [handleDelete]);

  return {
    loading,
    handleClick,
  };
};

export default useDeleteDiagram;
