import { memo, useCallback, useRef } from 'react';
import MyoshBowtieLogo from '../../assets/svg/myosh-bowtie-logo.svg';
import { useDiagramContext } from '../../context/diagram.context';
import { cn } from '../../helpers/util';
import useHeaderActions from '../../hooks/use-header-actions';
import { useAppSelector } from '../../redux/hooks';
import { selectDiagramDisabled, selectMueRecordId } from '../../redux/slices/diagram';
import { JobStatus, selectHasRunningJobs, selectSubmittedJobs } from '../../redux/slices/running-jobs';
import AiAnalysisSuggestionContainer, {
  AIAnalysisSuggestionContainerRef,
} from '../common/ai-analysis-suggestion-container';
import AISplitButton from './ai-split-button.component';
import ControlLegend from './control-legend.component';
import CreateSplitButton from './create-split-button.component';
import FilterOverlay from './filter-overlay.component';
import RecordDropDown from './record-drop-down.component';
import RunningJobsPanel from './running-jobs-panel.component';
import Settings from './settings.component';

function Header() {
  const { isAIGenerated, isSavedDiagram } = useDiagramContext();
  const { bowtieDtoCreate, bowtieDtoSave, bowtieDtoAnalyze, mueLabel } = useHeaderActions();

  const hasRunningJobs = useAppSelector(selectHasRunningJobs);
  const submittedJobs = useAppSelector(selectSubmittedJobs);
  const latestJobStatus = submittedJobs[0]?.status;

  const mueRecordId = useAppSelector(selectMueRecordId);
  const canSaveDiagram = !mueRecordId;

  const disableDiagram = useAppSelector(selectDiagramDisabled);

  const analysisRef = useRef<AIAnalysisSuggestionContainerRef>(null);

  const onAIAnalysisReady = useCallback((text: string) => {
    analysisRef.current?.show(text);
  }, []);

  return (
    <div className="bt-relative bt-flex bt-w-full bt-flex-col">
      {disableDiagram && <div className="bt-absolute bt-z-[1301] bt-h-full bt-w-full bt-bg-transparent" />}
      <div
        className="bt-sticky bt-left-0 bt-top-0 bt-z-[1300] bt-mt-0 bt-flex bt-h-14 bt-justify-between bt-bg-mono-1 bt-px-2 bt-pt-2"
        id="header"
      >
        <div className="bt-flex bt-flex-row bt-gap-3">
          <img src={MyoshBowtieLogo} alt="myosh-bowtie-logo" />
          {!isAIGenerated && (
            <>
              <RecordDropDown />
              <AISplitButton onAIAnalysisReady={onAIAnalysisReady} bowtieDtoAnalyze={bowtieDtoAnalyze} />
            </>
          )}
          {(isAIGenerated || isSavedDiagram || canSaveDiagram) && (
            <div
              className={cn('bt-flex bt-flex-row bt-gap-2', {
                'bt-hidden': hasRunningJobs || latestJobStatus === JobStatus.Success,
              })}
            >
              <CreateSplitButton
                bowtieDtoCreate={bowtieDtoCreate}
                bowtieDtoSave={bowtieDtoSave}
                mueLabel={mueLabel}
                mueRecordId={mueRecordId}
              />
            </div>
          )}
          <RunningJobsPanel submittedJobs={submittedJobs} />
          <FilterOverlay />
        </div>
        <div className="bt-flex bt-min-w-[435px]">
          <ControlLegend />
          <Settings />
        </div>
      </div>
      <AiAnalysisSuggestionContainer ref={analysisRef} />
    </div>
  );
}

export default memo(Header);
