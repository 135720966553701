import { Node, NodeProps } from '@xyflow/react';
import { memo, useCallback } from 'react';
import { useAppPermissionsContext } from '../../../context/app-permissions.context';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { addNode, selectCanAddNode, selectConsequencesLength } from '../../../redux/slices/diagram';
import BaseContainerNode, { BaseContainerNodeData } from './base-container-node.component';
import AddCauseConsequenceButton from './common/add-cause-consequence-button.component';

type ConsequecesContainerNodeData = BaseContainerNodeData;
export type ConsequencesContainerNodeType = Node<ConsequecesContainerNodeData>;
type ConsequencesContainerNodeProps = NodeProps<ConsequencesContainerNodeType>;

export const id = crypto.randomUUID();
const label = 'Consequences';
const className = 'bt-border-consequences-border bt-bg-consequences-bg bt-text-consequences-fg';
const data = { label, className };

const addButtonLabel = '+ Add Consequece';
const addButtonClassName =
  'bt-text-consequences-fg bt-mt-2 bt-flex bt-w-full bt-justify-center bt-border-2 bt-border-consequences-border bt-bg-consequences-bg bt-px-0 bt-py-2 bt-text-sm bt-text-consequences-border hover:bt-bg-consequences-border hover:bt-text-consequences-box-fg';

/**
 * A React functional component that renders a container node for Consequeces.
 *
 * This component is a wrapper around the `BaseContainerNode` and passes
 * all received props along with additional data to it.
 *
 * @component
 * @param {ConsequencesContainerNodeProps} props - The properties passed to the component.
 * @returns {JSX.Element} The rendered container node component.
 *
 * @example
 * ```tsx
 * <ConsequencesContainerNode />
 * ```
 */
const ConsequencesContainerNode = (props: ConsequencesContainerNodeProps): JSX.Element => {
  const canAddNode = useAppSelector(selectCanAddNode);
  const consequencesLength = useAppSelector(selectConsequencesLength);
  const dispatch = useAppDispatch();

  const {
    addNodePermission: { consequences: canAddConsequences },
  } = useAppPermissionsContext();

  const handleAdd = useCallback(() => {
    if (canAddConsequences) {
      dispatch(addNode({ type: 'consequence-node', data: { id: crypto.randomUUID() } }));
    }
  }, [canAddConsequences]);

  const renderAddButton = canAddConsequences && canAddNode;

  return (
    <>
      <BaseContainerNode {...props} id={id} data={data} />
      {renderAddButton && (
        <AddCauseConsequenceButton
          label={addButtonLabel}
          onClick={handleAdd}
          hasCausesOrConsequences={consequencesLength > 0}
          controlsClassName={addButtonClassName}
        />
      )}
    </>
  );
};

export default memo(ConsequencesContainerNode);
