import { Button, DropDown, DropDownNoSelection, DropDownResult, JsonData, OdinIcon } from '@myosh/odin-components';
import { forwardRef, Ref, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useGetGlobalPromptQuery } from '../../api/enhanced/enhanced-bowtie-api';
import { useBowtieHazardMueMaterialUnwantedEventsPostMutation } from '../../api/generated/bowtie-api';
import { cn } from '../../helpers/util';
import Accordion from './accordion.component';
import AddItemDialog from './add-item-dialog.component';
import './bowtie-wizard.styles.css';
import Dialog, { DialogRef } from './dialog';

interface BowtieWizardProps {}

export interface BowtieWizardRef {
  open: () => void;
  close: () => void;
}

interface WizardDropDownItem {
  text: string;
  value: string;
  mue: Array<DropDownResult>;
}

interface BowtieWizardState {
  canGenerateMUEs: boolean;
  loading: boolean;
  hazardMuePair: Array<WizardDropDownItem>;
  selectedMUE?: DropDownResult;
  selectedHazard?: DropDownResult;
  isAddHazard: boolean;
}

const initialState: BowtieWizardState = {
  canGenerateMUEs: false,
  loading: false,
  hazardMuePair: [],
  selectedMUE: undefined,
  selectedHazard: undefined,
  isAddHazard: true,
};

const addNewActionItem = [{ text: 'Add New', value: 'add-new', selected: false }];

const BowtieWizard = ({}: BowtieWizardProps, ref: Ref<BowtieWizardRef>) => {
  const [state, setState] = useState<BowtieWizardState>(initialState);
  const { canGenerateMUEs, loading, hazardMuePair, selectedMUE, selectedHazard, isAddHazard } = state;

  const dialogRef = useRef<DialogRef>(null);
  const addDataDialogRef = useRef<DialogRef>(null);
  const termInputRef = useRef<HTMLInputElement>(null);
  const promptInputRef = useRef<HTMLTextAreaElement>(null);
  const newItemInputRef = useRef<HTMLInputElement>(null);
  const errorTextRef = useRef<HTMLParagraphElement>(null);

  const [getMaterialUnwantedEvents] = useBowtieHazardMueMaterialUnwantedEventsPostMutation();
  const { data: globalPrompt } = useGetGlobalPromptQuery();

  useImperativeHandle(ref, () => ({
    open: () => dialogRef.current?.open(),
    close,
  }));

  const close = useCallback(() => dialogRef.current?.close(), []);

  const handleTermInputChange = () => {
    if (!termInputRef.current?.value) {
      setState(initialState);
    }
  };

  const handleGenerateMUEs = () => {
    if (termInputRef.current?.value && termInputRef.current.value.trim().length > 0) {
      setState((state) => ({
        ...state,
        canGenerateMUEs: true,
        loading: true,
        selectedMUE: undefined,
        selectedHazard: undefined,
      }));

      getMaterialUnwantedEvents({
        hazardMueRequest: {
          term: termInputRef.current.value.trim(),
          extra_prompt: promptInputRef?.current?.value.trim(),
        },
      })
        .unwrap()
        .then((data) => {
          const hazardMuePair = data.map(({ material_unwanted_event: materialUnwantedEvent, hazard }) => {
            return {
              text: hazard,
              value: hazard,
              mue: materialUnwantedEvent.map((mue) => {
                return {
                  text: mue,
                  value: mue,
                };
              }),
            };
          });

          setState((state) => ({ ...state, hazardMuePair }));
        })
        .catch(() => {
          toast.error('Unable to generate hazards and unwanted events, please try again later.');
        })
        .finally(() => {
          setState((state) => ({ ...state, loading: false }));
        });
    }
  };

  const handleMUESelectionChange = (
    materialUnwantedEvent?: DropDownResult | DropDownResult[] | DropDownNoSelection
  ) => {
    if (materialUnwantedEvent && !Array.isArray(materialUnwantedEvent)) {
      setState((state) => ({ ...state, selectedMUE: materialUnwantedEvent as DropDownResult }));
    }
  };

  const handleHazardSelectionChange = (hazard?: DropDownResult | DropDownResult[] | DropDownNoSelection) => {
    if (hazard && !Array.isArray(hazard)) {
      const newHazard = hazard as DropDownResult;
      if ('originalData' in hazard) {
        newHazard!.originalData!.mue = hazard?.originalData?.mue || [];
      }
      setState((state) => ({ ...state, selectedMUE: undefined, selectedHazard: newHazard as DropDownResult }));
    }
  };

  const handleGenerateBowtieClick = () => {
    if (
      termInputRef.current?.value &&
      termInputRef.current.value.trim().length > 0 &&
      selectedMUE?.value &&
      selectedHazard?.value
    ) {
      // open new page and load bowtie
      const params = new URLSearchParams({
        term: termInputRef.current.value.trim(),
        hazard: selectedHazard.value as string,
        mue: selectedMUE.value as string,
      });

      if (promptInputRef.current?.value) {
        params.append('prompt', promptInputRef.current.value);
      }

      window.open(`${window.location.origin}${window.location.pathname}?${params}`);
    }
  };

  const handleSubmitAddItem = async () => {
    const inputValue = newItemInputRef.current?.value?.trim();

    if (inputValue && inputValue.length > 0) {
      if (inputValue.length > 100) {
        if (errorTextRef.current) {
          errorTextRef.current.style.display = 'block';
          errorTextRef.current.textContent = 'Value must not exceed 100 characters';
        }
      } else {
        if (errorTextRef.current) errorTextRef.current.style.display = 'none';

        if (isAddHazard) {
          setState((state) => ({
            ...state,
            loading: true,
          }));

          await getMaterialUnwantedEvents({
            hazardMueRequest: {
              term: termInputRef?.current?.value.trim() as string,
              extra_prompt: promptInputRef?.current?.value.trim(),
              hazard: inputValue,
            },
          })
            .unwrap()
            .then((data) => {
              const newHazardMuePair = [...hazardMuePair];
              newHazardMuePair.push({
                mue: data[0].material_unwanted_event.map((mue) => {
                  return {
                    text: mue,
                    value: mue,
                  };
                }),
                text: inputValue,
                value: inputValue,
              });

              const newSelectedHazard = {
                text: data[0].hazard,
                value: data[0].hazard,
                originalData: {
                  mue: data[0].material_unwanted_event.map((mue) => {
                    return {
                      text: mue,
                      value: mue,
                    };
                  }),
                },
              };

              setState((prevState) => ({
                ...prevState,
                loading: false,
                selectedMUE: undefined,
                hazardMuePair: newHazardMuePair,
                selectedHazard: newSelectedHazard,
              }));
            })
            .catch(() => {
              toast.error('Unable to generate hazards and unwanted events, please try again later.');
            })
            .finally(() => {
              setState((state) => ({ ...state, loading: false }));
            });
        } else {
          const newSelectedHazard = { ...selectedHazard };

          (newSelectedHazard?.originalData?.mue as { text: string; value: string }[]).push({
            text: inputValue,
            value: inputValue,
          });

          const newHazardMuePair = hazardMuePair.map((item) => {
            if (item.value === selectedHazard?.value) {
              return {
                ...item,
                mue: newSelectedHazard?.originalData?.mue as DropDownResult[],
              };
            }
            return item;
          });

          setState((prevState) => ({
            ...prevState,
            hazardMuePair: newHazardMuePair,
            selectedHazard: newSelectedHazard as DropDownResult,
            selectedMUE: {
              value: inputValue,
              text: inputValue,
              originalData: {
                value: inputValue,
                text: inputValue,
              },
            },
          }));
        }

        addDataDialogRef.current?.close();
        if (newItemInputRef.current) newItemInputRef.current.value = '';
      }
    } else {
      if (errorTextRef.current) {
        errorTextRef.current.style.display = 'block';
        errorTextRef.current.textContent = 'Must enter a value';
      }
    }
  };

  const handleShowHazardAddItemDialog = () => {
    setState((state) => ({ ...state, isAddHazard: true }));
    addDataDialogRef.current?.open();
  };

  const handleShowMueAddItemDialog = () => {
    setState((state) => ({ ...state, isAddHazard: false }));
    addDataDialogRef.current?.open();
  };

  const sectionStyles =
    'hide-checkbox-container bt-flex bt-flex-1 bt-flex-col bt-items-center bt-gap-4 bt-rounded-md bt-bg-gray-5 bt-p-4';

  const HazardDropDownSelectorStyles = cn('bt-text-sm', {
    "before:bt-content-['Select_Hazard'] before:bt-text-gray-3": !selectedHazard,
  });

  const MUEDropDownSelectorStyles = cn('bt-text-sm', {
    "before:bt-content-['Select_Unwanted_Event'] before:bt-text-gray-3": !selectedMUE,
  });

  useEffect(() => {
    if (promptInputRef.current) {
      promptInputRef.current.value = globalPrompt?.prompt || '';
    }
  }, [globalPrompt]);

  return (
    <>
      <Dialog
        className="bt-m-auto bt-w-[600px] bt-rounded-md bt-p-4 bt-text-gray-1 bt-outline-none"
        ref={dialogRef}
        onCancel={close}
      >
        <div className="bt-mb-4 bt-flex bt-flex-row bt-items-center bt-justify-between">
          <div className="bt-text-xl bt-font-medium">Bowtie AI Wizard</div>
          <div title="Close" className="bt-cursor-pointer bt-text-primary-2" onClick={close}>
            <OdinIcon icon="Close" />
          </div>
        </div>
        <div className="bt-flex bt-flex-col bt-gap-4">
          <div className={sectionStyles}>
            <div className="bt-w-full">
              <label htmlFor="term-input" className="bt-flex bt-self-start bt-text-sm bt-font-bold">
                Describe an area or term to generate Unwanted Events for
              </label>
              <input
                ref={termInputRef}
                name="term-input"
                onChange={handleTermInputChange}
                className="bt-flex bt-w-full bt-flex-1 bt-appearance-none bt-border bt-border-gray-4 bt-px-2 bt-py-1 bt-text-sm focus:bt-outline-none"
              />
            </div>
            <Button
              variant="alternative"
              onClick={handleGenerateMUEs}
              disabled={canGenerateMUEs && loading}
              style={{ minWidth: '250px' }}
            >
              {canGenerateMUEs && loading ? 'Generating...' : 'Generate Hazards and Events'}
            </Button>
          </div>
          <div className={sectionStyles}>
            <DropDown
              className={cn({
                'bt-pointer-events-none bt-opacity-50': !canGenerateMUEs || loading,
              })}
              label="Hazards"
              data={hazardMuePair as unknown as JsonData}
              textField="text"
              valueField="value"
              allowClear={false}
              allowSearch={true}
              onChange={handleHazardSelectionChange}
              value={selectedHazard}
              selectorStyles={HazardDropDownSelectorStyles}
              actionItems={addNewActionItem}
              onActionItemChanged={handleShowHazardAddItemDialog}
            />
            <DropDown
              className={cn({
                'bt-pointer-events-none bt-opacity-50': !canGenerateMUEs || loading || !selectedHazard?.value,
              })}
              label="Unwanted Events"
              data={selectedHazard?.originalData?.mue as unknown as JsonData}
              textField="text"
              valueField="value"
              allowClear={false}
              allowSearch={true}
              onChange={handleMUESelectionChange}
              value={selectedMUE}
              selectorStyles={MUEDropDownSelectorStyles}
              actionItems={addNewActionItem}
              onActionItemChanged={handleShowMueAddItemDialog}
            />
            <Accordion title="Advanced">
              <div className="bt-w-full">
                <label htmlFor="custom-instructions" className="bt-flex bt-self-start bt-text-sm bt-font-bold">
                  Custom Instructions
                </label>
                <textarea
                  ref={promptInputRef}
                  name="custom-instructions"
                  placeholder="Provide extra detail about your situation to generate a more relevant response"
                  className="bt-flex bt-w-full bt-flex-1 bt-resize-none bt-appearance-none bt-border bt-border-gray-4 bt-px-2 bt-py-1 bt-text-sm focus:bt-outline-none"
                  cols={30}
                  rows={5}
                />
              </div>
            </Accordion>
            <Button
              variant="alternative"
              type="primary"
              onClick={handleGenerateBowtieClick}
              disabled={!selectedMUE || !selectedHazard}
              style={{ maxWidth: '200px' }}
            >
              Generate Bowtie
            </Button>
            <p className="bt-self-start bt-text-xs bt-text-warning">
              Warning: Data is sent to an external AI. Verify all AI results for accuracy.
            </p>
          </div>
        </div>
      </Dialog>
      <AddItemDialog
        ref={addDataDialogRef}
        errorTextRef={errorTextRef}
        inputRef={newItemInputRef}
        onSubmit={handleSubmitAddItem}
        disabled={loading}
      />
    </>
  );
};

export default forwardRef(BowtieWizard);
