import { Node, NodeProps } from '@xyflow/react';
import { memo, useCallback } from 'react';
import { useAppPermissionsContext } from '../../../context/app-permissions.context';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { addNode, selectCanAddNode, selectCausesLength } from '../../../redux/slices/diagram';
import BaseContainerNode, { BaseContainerNodeData } from './base-container-node.component';
import AddCauseConsequenceButton from './common/add-cause-consequence-button.component';

type CausesContainerNodeData = BaseContainerNodeData;
export type CausesContainerNodeType = Node<CausesContainerNodeData>;
type CausesContainerNodeProps = NodeProps<CausesContainerNodeType>;

export const id = crypto.randomUUID();
const label = 'Causes';
const className = 'bt-border-causes-border bt-bg-causes-bg bt-text-causes-fg bt-relative';
const data = { label, className };

const addButtonLabel = '+ Add Cause';
const addButtonClassName =
  'bt-text-causes-fg bt-mt-2 bt-flex bt-w-full bt-justify-center bt-border-2 bt-border-causes-border bt-bg-causes-bg bt-px-0 bt-py-2 bt-text-sm bt-text-causes-border hover:bt-bg-causes-border hover:bt-text-causes-box-fg';

/**
 * A React functional component that renders a container node for Causes.
 *
 * This component is a wrapper around the `BaseContainerNode` and passes
 * all received props along with additional data to it.
 *
 * @component
 * @param {CausesContainerNodeProps} props - The properties passed to the component.
 * @returns {JSX.Element} The rendered container node component.
 *
 * @example
 * ```tsx
 * <CausesContainerNode />
 * ```
 */
const CausesContainerNode = (props: CausesContainerNodeProps): JSX.Element => {
  const canAddNode = useAppSelector(selectCanAddNode);
  const causesLength = useAppSelector(selectCausesLength);
  const dispatch = useAppDispatch();

  const {
    addNodePermission: { causes: canAddCauses },
  } = useAppPermissionsContext();

  const handleAdd = useCallback(() => {
    if (canAddCauses) {
      dispatch(addNode({ type: 'cause-node', data: { id: crypto.randomUUID() } }));
    }
  }, [canAddCauses]);

  const renderAddButton = canAddCauses && canAddNode;

  return (
    <>
      <BaseContainerNode {...props} id={id} data={data} />
      {renderAddButton && (
        <AddCauseConsequenceButton
          label={addButtonLabel}
          onClick={handleAdd}
          hasCausesOrConsequences={causesLength > 0}
          controlsClassName={addButtonClassName}
        />
      )}
    </>
  );
};

export default memo(CausesContainerNode);
