import { memo } from 'react';
import LoadingPreview from '../../../components/common/loading-preview';
import { cn } from '../../../helpers/util';

interface DiagramLoadingProps {
  loading?: boolean;
}
interface NodesContainerProps {
  title: string;
  message: string;
  isControl?: boolean;
  classNames?: string;
}

interface HazardRiskNoteProps {
  message: string;
}

const NodesContainer = memo(function NodesContainer({
  title,
  message,
  isControl = false,
  classNames,
}: NodesContainerProps) {
  return (
    <div
      className={cn(
        'bt-flex bt-h-full bt-w-full bt-flex-col bt-justify-center bt-rounded-md bt-border-2 bt-border-dashed bt-px-2 bt-py-4 bt-text-center',
        { 'bt-w-[75%]': !isControl },
        classNames
      )}
    >
      <div>
        <h4 className="bt-mb-16 bt-text-base bt-font-bold">{title}</h4>
      </div>
      <div className="bt-flex bt-h-full bt-items-center bt-justify-center">
        <LoadingPreview message={message} />
      </div>
    </div>
  );
});

const HazardRiskNode = memo(function HazardRiskNode({ message }: HazardRiskNoteProps) {
  return (
    <div className="bt-flex bt-h-20 bt-w-40 bt-items-center bt-justify-center bt-rounded-md bt-border bt-border-dashed bt-border-mue-border bt-p-2 bt-text-center bt-text-xs">
      <LoadingPreview message={message} />
    </div>
  );
});

const DiagramLoading = ({ loading = false }: DiagramLoadingProps) => (
  <div
    className={cn('bt-absolute bt-z-[1] bt-flex bt-h-full bt-w-full bt-space-x-4 bt-bg-mono-1 bt-px-16 bt-py-12', {
      'bt-hidden': !loading,
    })}
  >
    <NodesContainer
      title="Causes"
      message="Causes will be displayed here"
      classNames="bt-bg-causes-bg bt-border-causes-border"
    />
    <NodesContainer
      isControl={true}
      title="Preventative Controls"
      message="Preventative Controls will be displayed here"
      classNames="bt-bg-pc-bg bt-border-pc-border"
    />
    <div className="bt-mx-12 bt-flex bt-h-[55%] bt-w-full bt-flex-col bt-items-center bt-justify-between">
      <HazardRiskNode message="Hazard will be displayed here" />
      <HazardRiskNode message="Risk will be displayed here" />
    </div>
    <NodesContainer
      isControl={true}
      title="Mitigating Controls"
      message="Mitigating Controls will be displayed here"
      classNames="bt-bg-mc-bg bt-border-mc-border"
    />
    <NodesContainer
      title="Consequences"
      message="Consequences will be displayed here"
      classNames="bt-bg-consequences-bg bt-border-consequences-border"
    />
  </div>
);

export default memo(DiagramLoading);
