import { Node, NodeProps } from '@xyflow/react';
import { useMemo } from 'react';
import { cn } from '../../../helpers/util';
import { useAppSelector } from '../../../redux/hooks';
import {
  selectConsequencesLength,
  selectControlsLengthByConsequenceId,
  selectMoving,
} from '../../../redux/slices/diagram';
import BaseNode, { BaseNodeData } from './base-node.component';
import { HandlesProps } from './common/handles.component';
import VerticalMoveControl, { VerticalMoveDirection } from './common/vertical-move-control.component';

type ConsequenceNodeData = BaseNodeData;
export type ConsequenceNodeType = Node<ConsequenceNodeData>;
type ConsequenceNodeProps = NodeProps<ConsequenceNodeType>;

const className = 'bt-bg-consequences-box-bg bt-border-consequences-box-border bt-text-consequences-box-fg';
const iconClassName = 'bt-text-consequences-box-bg bt-stroke-consequences-box-fg';
const handles: HandlesProps = { leftHandleId: 'left', leftHandleType: 'source' };
const staticData = { className, iconClassName, handles };

/**
 * A component that represents a consequence node in a flow diagram.
 * Extends the base node component with consequence-specific functionality.
 *
 * @component
 * @param {ConsequenceNodeProps} props - The properties for the consequence node component
 * @returns {JSX.Element} A rendered consequence node component
 *
 * @example
 * ```tsx
 * <ConsequenceNode
 *   id="consequence-1"
 *   data={{ label: "Root Consequence" }}
 *   position={{ x: 100, y: 100 }}
 * />
 * ```
 */
const ConsequenceNode = (props: ConsequenceNodeProps): JSX.Element => {
  const consequencesLength = useAppSelector(selectConsequencesLength);
  const controlsLength = useAppSelector((state) => selectControlsLengthByConsequenceId(state, props.data.recordId!));

  const moving = useAppSelector(selectMoving);
  const { rowIndex } = props.data;

  const canMoveUp = useMemo(() => (rowIndex !== undefined ? rowIndex > 0 : false), [rowIndex]);

  const canMoveDown = useMemo(
    () => (rowIndex !== undefined ? rowIndex < consequencesLength - 1 : false),
    [rowIndex, consequencesLength]
  );

  const hasControls = controlsLength > 0;

  const data = useMemo(() => {
    const className = cn(staticData.className, { 'bt-animate-wobble': moving && (canMoveUp || canMoveDown) });
    return { ...props.data, ...staticData, className, showUnlinkActionIcon: !props.data.recordId || !hasControls };
  }, [props.data, canMoveUp, canMoveDown, moving]);

  return (
    <div className="bt-group bt-flex bt-items-center">
      <VerticalMoveControl
        id={props.id}
        type="consequence-node"
        index={data.rowIndex!}
        title="Move Up"
        direction={VerticalMoveDirection.UP}
        disabled={!moving || !canMoveUp}
      />

      <BaseNode {...props} data={data} />

      <VerticalMoveControl
        id={props.id}
        type="consequence-node"
        index={data.rowIndex!}
        title="Move Down"
        direction={VerticalMoveDirection.DOWN}
        disabled={!moving || !canMoveDown}
      />
    </div>
  );
};

export default ConsequenceNode;
