import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

function useMainHooks() {
  let isAIGenerated = false;
  let isNewDiagram = false;
  let isSavedDiagram = false; // no backend records created yet

  const { search } = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);

  if (queryParams.has('term') && queryParams.has('hazard') && queryParams.has('mue')) {
    isAIGenerated = true;
    isNewDiagram = false;
    isSavedDiagram = false;
  } else if (queryParams.has('import')) {
    isAIGenerated = true;
    isNewDiagram = false;
    isSavedDiagram = false;
  } else if (queryParams.has('records') || queryParams.has('recordId')) {
    isAIGenerated = false;
    isNewDiagram = false;
    isSavedDiagram = false;
  } else if (queryParams.has('diagramId')) {
    isAIGenerated = false;
    isNewDiagram = false;
    isSavedDiagram = true;
  } else {
    isAIGenerated = false;
    isNewDiagram = true;
    isSavedDiagram = false;
  }

  return {
    isAIGenerated,
    isNewDiagram,
    isSavedDiagram,
  };
}

export default useMainHooks;
