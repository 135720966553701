// NOTE: https://myosh.atlassian.net/browse/MYOSH-8345?focusedCommentId=64601
export const criticalFilterValues = {
  truthy: 'Critical',
  falsy: 'Non Critical',
};

export const effectiveFilterValues = {
  truthy: 'Effective',
  falsy: 'Not Effective',
};

export const initialImplementationRequiredValues = {
  truthy: 'Yes',
  falsy: 'No',
};

export const materialRiskRelationValues = {
  truthy: 'Yes',
  falsy: 'No',
};

export enum DIAGRAM_MODE {
  BOWTIE = 'BOWTIE',
  BUTTERFLY = 'BUTTERFLY',
}
