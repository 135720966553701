import { useMemo } from 'react';
import { CauseDiagramNode, ConsequenceDiagramNode, ControlDiagramNode, DiagramNode } from '../@types/diagram';
import { BowtieRequestWithId } from '../api/@types/enhanced-bowtie-api.types';
import { ControlDto, BowtieDto as SaveBowtieDto } from '../api/generated/bowtie-api';
import { BowtieDto } from '../api/generated/v4-api';
import {
  criticalFilterValues,
  initialImplementationRequiredValues,
  materialRiskRelationValues,
} from '../helpers/constants';
import { CriticalControlEnum } from '../helpers/node-util';
import { useAppSelector } from '../redux/hooks';
import { selectCauses, selectConsequences, selectHazard, selectMueLabel } from '../redux/slices/diagram';
import useControlValues, { ValueResolvers } from './use-control-value-resolvers';

/**
 * A custom hook that manages the header actions for a Bow-tie diagram.
 *
 * This hook provides access to the MUE label and various Bowtie DTOs (Data Transfer Objects)
 * that can be used for creation, analysis, and saving operations.
 *
 * @returns An object containing:
 * - `mueLabel`: The current MUE (Most Unwanted Event) label
 * - `bowtieDtoCreate`: DTO for creating a new Bow-Tie diagram, including value resolvers
 * - `bowtieDtoAnalyze`: DTO for analyzing an existing Bow-Tie diagram
 * - `bowtieDtoSave`: DTO for saving a Bow-Tie diagram
 *
 * @example
 * ```tsx
 * const { mueLabel, bowtieDtoCreate, bowtieDtoAnalyze, bowtieDtoSave } = useHeaderActions();
 * ```
 */
const useHeaderActions = () => {
  const mueLabel = useAppSelector(selectMueLabel);
  const hazard = useAppSelector(selectHazard);
  const causes = useAppSelector(selectCauses);
  const consequences = useAppSelector(selectConsequences);

  const { valueResolvers, isFetching: isFetchingResolvers } = useControlValues();

  const bowtieDtoCreate = useMemo(
    () =>
      hazard && !isFetchingResolvers
        ? buildCreateBowtieDto(
            mueLabel,
            hazard,
            causes as Array<CauseDiagramNode>,
            consequences as Array<ConsequenceDiagramNode>,
            valueResolvers
          )
        : undefined,
    [mueLabel, hazard, causes, consequences, isFetchingResolvers]
  );

  const bowtieDtoAnalyze = useMemo(
    () =>
      hazard
        ? buildAnalyzeBowtieDto(
            mueLabel,
            hazard,
            causes as Array<CauseDiagramNode>,
            consequences as Array<ConsequenceDiagramNode>
          )
        : undefined,
    [mueLabel, hazard, causes, consequences]
  );

  const bowtieDtoSave = useMemo(
    () =>
      hazard
        ? buildSaveBowtieDto(
            mueLabel,
            hazard,
            causes as Array<CauseDiagramNode>,
            consequences as Array<ConsequenceDiagramNode>
          )
        : undefined,
    [mueLabel, hazard, causes, consequences]
  );

  return {
    mueLabel,
    bowtieDtoCreate,
    bowtieDtoAnalyze,
    bowtieDtoSave,
  };
};

export default useHeaderActions;

// Builds the bowtieDto object used for 'creating' the backend records
const buildCreateBowtieDto = (
  mueLabel: string | undefined,
  hazard: DiagramNode,
  causes: Array<CauseDiagramNode>,
  consequences: Array<ConsequenceDiagramNode>,
  valueResolvers: ValueResolvers
) => {
  return {
    hazard: hazard.label,
    riskScenario: mueLabel,
    causes: causes
      .filter((cause) => cause.label)
      .map((cause) => ({
        cause: cause.label,
        controls: cause.controls
          .filter((control) => control.label)
          .map((control) => _buildCreateBowtieDtoControl(control, valueResolvers)),
      })),
    consequences: consequences
      .filter((consequence) => consequence.label)
      .map((consequence) => ({
        consequence: consequence.label,
        controls: consequence.controls
          .filter((control) => control.label)
          .map((control) => _buildCreateBowtieDtoControl(control, valueResolvers)),
      })),
  } as BowtieDto;
};

const _buildCreateBowtieDtoControl = (control: ControlDiagramNode, valueResolvers: ValueResolvers) => {
  const {
    criticalRankIdResolver,
    categoryIdResolver,
    controlTypeResolver,
    initialImplementationRequiredIdResolver,
    materialRiskRelationIdResolver,
  } = valueResolvers;

  let _criticalRankId, _categoryId, _typeId, _initialImplementationRequiredId, _materialRiskRelationFieldId;

  if (control._metadata?.aiData) {
    const { isCritical, category, controlType, isInitial, isControlIssues } = control._metadata.aiData;

    if (isCritical) {
      _criticalRankId = criticalRankIdResolver(criticalFilterValues.truthy);
    } else {
      _criticalRankId = criticalRankIdResolver(criticalFilterValues.falsy);
    }

    _categoryId = categoryIdResolver(category);

    _typeId = controlTypeResolver(controlType);

    if (isInitial) {
      _initialImplementationRequiredId = initialImplementationRequiredIdResolver(
        initialImplementationRequiredValues.truthy
      );
    } else {
      _initialImplementationRequiredId = initialImplementationRequiredIdResolver(
        initialImplementationRequiredValues.falsy
      );
    }

    if (isControlIssues) {
      _materialRiskRelationFieldId = materialRiskRelationIdResolver(materialRiskRelationValues.truthy);
    } else {
      _materialRiskRelationFieldId = materialRiskRelationIdResolver(materialRiskRelationValues.falsy);
    }
  }

  return {
    name: control.label,
    isGlobal: control.global,
    criticalRankId: _criticalRankId,
    categoryId: _categoryId,
    typeId: _typeId,
    initialImplementationRequiredId: _initialImplementationRequiredId,
    materialRiskRelationId: _materialRiskRelationFieldId,
  };
};

// Builds the bowtieDto object used for 'analyzing' the diagram
const buildAnalyzeBowtieDto = (
  mueLabel: string | undefined,
  hazard: DiagramNode,
  causes: Array<CauseDiagramNode>,
  consequences: Array<ConsequenceDiagramNode>
) => {
  return {
    term: '',
    hazard: hazard.label ?? '',
    risk: mueLabel ?? '',
    causes: causes
      .filter((cause) => cause.label)
      .map((cause) => ({
        id: cause.id,
        name: cause.label,
        controls: cause.controls
          .filter((control) => control.label)
          .map((control) => ({
            id: control.id,
            name: control.label,
            isGlobal: control.global,
            isCritical: control.criticalControlType === CriticalControlEnum.CRITICAL,
            type: 'Preventative',
            // FIXME: These should be resolved from the underlying record data
            isInitial: false,
            category: 'Soft',
          })),
      })),
    consequences: consequences
      .filter((consequence) => consequence.label)
      .map((consequence) => ({
        id: consequence.id,
        name: consequence.label,
        controls: consequence.controls
          .filter((control) => control.label)
          .map((control) => ({
            id: control.id,
            name: control.label,
            isGlobal: control.global,
            isCritical: control.criticalControlType === CriticalControlEnum.CRITICAL,
            type: 'Mitigating',
            // FIXME: These should be resolved from the underlying record data
            isInitial: false,
            category: 'Soft',
          })),
      })),
  } as BowtieRequestWithId;
};

// Builds the bowtieDto object used for 'saving' the diagram
const buildSaveBowtieDto = (
  mueLabel: string | undefined,
  hazard: DiagramNode,
  causes: Array<CauseDiagramNode>,
  consequences: Array<ConsequenceDiagramNode>
) => {
  return {
    hazard: hazard.label ?? '',
    risk: mueLabel ?? '',
    causes: causes
      .filter((cause) => cause.label)
      .map((cause) => ({
        name: cause.label,
        controls: cause.controls
          .filter((control) => control.label)
          .map((control) => ({
            ..._buildSaveBowtieDtoControl(control),
            type: 'Preventative',
          })),
      })),
    consequences: consequences
      .filter((consequence) => consequence.label)
      .map((consequence) => ({
        name: consequence.label,
        controls: consequence.controls
          .filter((control) => control.label)
          .map((control) => ({
            ..._buildSaveBowtieDtoControl(control),
            type: 'Mitigating',
          })),
      })),
  } as SaveBowtieDto;
};

const _buildSaveBowtieDtoControl = (control: ControlDiagramNode) => {
  let _control: ControlDto = { name: control.label, isGlobal: control.global };

  if (control._metadata?.aiData) {
    const { isCritical, category, controlType, isInitial } = control._metadata.aiData;

    _control = {
      ..._control,
      isCritical,
      isInitial,
      controlType,
      category,
    };
  }

  return _control;
};
