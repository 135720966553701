import { Button, ModalDialogRef, OdinIcon, OdinIconSize, OdinIconType, SplitButton } from '@myosh/odin-components';
import { memo, useCallback, useMemo, useRef } from 'react';
import { BowtieRequestWithId } from '../../api/@types/enhanced-bowtie-api.types';
import { useDiagramContext } from '../../context/diagram.context';
import { useUserPermissionsContext } from '../../context/user-permissions.context';
import { useAIAnalysis } from '../../hooks/use-ai-analysis';
import AiAnalysisConfirmationModal from './ai-analysis-confirmation.modal';
import BowtieWizard, { BowtieWizardRef } from './bowtie-wizard';
import ImportBowtieDiagram from './import-bowtie-diagram';

interface AISplitButtonProps {
  onAIAnalysisReady: (text: string) => void;
  bowtieDtoAnalyze?: BowtieRequestWithId;
}

/**
 * A component that renders either a SplitButton or a regular Button based on AI feature availability.
 * The SplitButton provides additional AI-related functionality like AI Wizard, Import, and Analysis options.
 *
 * @component
 * @param {Object} props - Component props
 * @param {Function} props.onAIAnalysisReady - Callback function triggered when AI analysis is complete
 * @param {Object} props.bowtieDtoAnalyze - DTO containing bowtie diagram data for AI analysis
 *
 * @example
 * ```tsx
 * <AISplitButton
 *   onAIAnalysisReady={(result) => handleAnalysisResult(result)}
 *   bowtieDtoAnalyze={bowtieDiagramData}
 * />
 * ```
 *
 * @remarks
 * - Displays a SplitButton when AI features are enabled, otherwise shows a regular Button
 * - Provides options for AI Wizard, Import functionality, and AI Analysis
 * - Handles confirmation dialogs for AI analysis with option to remember user preference
 * - Integrates with the diagram context to manage state and permissions
 */
const AISplitButton = ({ onAIAnalysisReady, bowtieDtoAnalyze }: AISplitButtonProps) => {
  const wizardRef = useRef<BowtieWizardRef>(null);
  const importBowtieDiagramRef = useRef<HTMLInputElement>(null);
  const aiAnalysisConfirmationModalRef = useRef<ModalDialogRef>(null);

  const { isNewDiagram } = useDiagramContext();
  // FIXME: the ai analysis should fetch the data from the context/state
  const { runAIAnalysis, isLoading: aiAnalysisRunning } = useAIAnalysis(onAIAnalysisReady, bowtieDtoAnalyze!);

  // FIXME:  'aiEnabled' should be moved out of the user permissions context
  const { aiEnabled } = useUserPermissionsContext();

  const hideAiAnalysisConfirmation = localStorage.getItem('hideAiAnalysisConfirmation');

  const handleBowtieWizardClick = useCallback(() => {
    return wizardRef.current?.open();
  }, []);

  const handleImportBowtie = useCallback(() => {
    importBowtieDiagramRef.current?.click();
  }, []);

  const handleRunAiAnalysis = useCallback(() => {
    if (!Boolean(hideAiAnalysisConfirmation)) {
      aiAnalysisConfirmationModalRef.current?.show();
      return;
    }

    runAIAnalysis();
  }, [hideAiAnalysisConfirmation, runAIAnalysis]);

  const handleSubmitConfirmation = useCallback(
    (isAiAnalysisConfirmationCheckboxChecked: boolean) => {
      if (isAiAnalysisConfirmationCheckboxChecked) {
        localStorage.setItem('hideAiAnalysisConfirmation', 'true');
      }

      runAIAnalysis();
      aiAnalysisConfirmationModalRef.current?.close();
    },
    [runAIAnalysis]
  );

  const handleAddBowtie = useCallback(() => {
    window.open(`${window.location.origin}${window.location.pathname}`);
  }, []);

  const splitButtonItems = useMemo(
    () => [
      {
        label: 'Bowtie AI Wizard',
        command: handleBowtieWizardClick,
        icon: <OdinIcon icon="Magic" type={OdinIconType.Line} size={OdinIconSize.ExtraSmall} className="bt-mr-2" />,
      },
      {
        label: 'Import Bowtie',
        command: handleImportBowtie,
        icon: <OdinIcon icon="Upload" type={OdinIconType.Line} size={OdinIconSize.ExtraSmall} className="bt-mr-2" />,
      },
      {
        label: 'AI Analysis',
        command: handleRunAiAnalysis,
        visible: !isNewDiagram,
        disabled: aiAnalysisRunning,
        icon: <OdinIcon icon="Terminal" type={OdinIconType.Line} size={OdinIconSize.ExtraSmall} className="bt-mr-2" />,
      },
    ],
    [handleBowtieWizardClick, handleImportBowtie, handleRunAiAnalysis, isNewDiagram, aiAnalysisRunning]
  );

  return (
    <>
      {aiEnabled ? (
        <SplitButton
          label="Add Bowtie"
          onClick={handleAddBowtie}
          model={splitButtonItems}
          variant="alternative"
          className="bt-min-w-52"
          icon={<OdinIcon icon="Add" type={OdinIconType.Line} size={OdinIconSize.ExtraSmall} className="bt-mr-2" />}
        />
      ) : (
        <Button
          icon={<OdinIcon icon="Add" type={OdinIconType.Line} size={OdinIconSize.ExtraSmall} className="bt-mr-2" />}
          classNames="bt-min-w-52"
          variant="alternative"
          type="primary"
          onClick={handleAddBowtie}
        >
          Add Bowtie
        </Button>
      )}
      <BowtieWizard ref={wizardRef} />
      <ImportBowtieDiagram ref={importBowtieDiagramRef} />
      <AiAnalysisConfirmationModal
        ref={aiAnalysisConfirmationModalRef}
        handleSubmitConfirmation={handleSubmitConfirmation}
        handleCloseModal={() => aiAnalysisConfirmationModalRef.current?.close()}
      />
    </>
  );
};

export default memo(AISplitButton);
