import { debounce } from 'lodash';
import { forwardRef, Ref, useCallback, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { ControlDiagramNode } from '../../../../@types/diagram';
import { TransformedSimpleRecordDto } from '../../../../api/@types/enhanced-v4-api.types';
import { useLazyGetSimpleRecordsQuery } from '../../../../api/enhanced/enhanced-v4-api';
import { useConfigurationContext } from '../../../../context/configuration.context';
import { isControlNode } from '../../../../helpers/node-util';
import { resolveCriticalControlType, resolveEffectiveControlType } from '../../../../hooks/use-record';
import { useAppSelector } from '../../../../redux/hooks';
import { selectMueRecordId } from '../../../../redux/slices/diagram';
import RecordsList from './records-list.component';

interface NodeTextAreaProps {
  type: string;
  placeholder?: string;
  defaultValue?: string;
  onKeyDown: (
    event: React.KeyboardEvent,
    data?: Partial<ControlDiagramNode> & { control: TransformedSimpleRecordDto }
  ) => void;
  onItemClick: (data: Partial<ControlDiagramNode> & { control: TransformedSimpleRecordDto }) => void;
  onBlur: () => void;
}

/**
 * A React component that renders a textarea with optional search functionality for records.
 *
 * @component
 * @param {Object} props - The component props
 * @param {string} props.type - The type of the node
 * @param {string} [props.defaultValue] - The default value for the textarea
 * @param {string} [props.placeholder] - Placeholder text for the textarea
 * @param {function} props.onKeyDown - Callback function triggered on keydown events
 * @param {function} props.onItemClick - Callback function triggered when a search result item is clicked
 * @param {function} props.onBlur - Callback function triggered when the component loses focus
 * @param {React.Ref<HTMLTextAreaElement>} forwardRef - Ref object for the textarea element
 *
 * @example
 * ```tsx
 * <NodeTextArea
 *   type="control"
 *   placeholder="Search..."
 *   onKeyDown={(e) => handleKeyDown(e)}
 *   onItemClick={(item) => handleItemClick(item)}
 *   onBlur={() => handleBlur()}
 *   ref={textareaRef}
 * />
 * ```
 */
const NodeTextArea = (
  { type, defaultValue, placeholder, onKeyDown, onItemClick, onBlur }: NodeTextAreaProps,
  forwardRef: Ref<HTMLTextAreaElement>
) => {
  const [loading, setLoading] = useState(false);
  const [records, setRecords] = useState<Array<TransformedSimpleRecordDto>>([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const mueRecordId = useAppSelector(selectMueRecordId);

  const isSearchable = useMemo(
    () => mueRecordId && !defaultValue && isControlNode(type),
    [defaultValue, type, mueRecordId]
  );

  const searchPerformedRef = useRef(false);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  useImperativeHandle(forwardRef, () => inputRef.current as HTMLTextAreaElement);

  const {
    controls: {
      formId,
      fieldId: captionFieldId,
      criticalOrNonCriticalFieldId,
      effectiveOrNotEffectiveField,
      isGlobalFieldId: globalFieldKey,
    },
  } = useConfigurationContext();

  const [getRecords] = useLazyGetSimpleRecordsQuery();

  const buildNodeObject = useCallback((record: TransformedSimpleRecordDto) => {
    const label = (record.fields?.[captionFieldId] as string) ?? '';
    const recordId = record.id;
    const linkUrl = record.linkUrl;
    const global = record.fields?.['Global'] === true;
    const criticalOrNonCritical = resolveCriticalControlType(record.fields?.[criticalOrNonCriticalFieldId]);
    const effectiveOrNotEffective = resolveEffectiveControlType(
      (record as Record<string, unknown>)[effectiveOrNotEffectiveField]
    );

    return {
      control: record, // the existing/selected record from the list
      label,
      recordId,
      linkUrl,
      global,
      criticalControlType: criticalOrNonCritical,
      effectiveControlType: effectiveOrNotEffective,
    };
  }, []);

  const handleOnKeyDownEvent = useCallback(
    (event: React.KeyboardEvent) => {
      if (!isSearchable) {
        onKeyDown(event);
        return;
      }

      if (event.code === 'ArrowDown') {
        event.preventDefault();
        event.stopPropagation();
        setSelectedIndex((prev) => Math.min(prev + 1, records.length - 1));
      } else if (event.code === 'ArrowUp') {
        event.preventDefault();
        event.stopPropagation();
        setSelectedIndex((prev) => Math.max(prev - 1, 0));
      } else if (
        (event.code === 'Enter' || event.code === 'NumpadEnter') &&
        isSearchable &&
        records.length > 0 &&
        selectedIndex >= 0 &&
        selectedIndex < records.length
      ) {
        event.preventDefault();
        event.stopPropagation();

        const record = records[selectedIndex];
        const nodeObject = buildNodeObject(record);
        onKeyDown(event, nodeObject);
      } else {
        onKeyDown(event);
      }
    },
    [onKeyDown, records, selectedIndex, isSearchable]
  );

  const handleOnItemClick = useCallback(
    (index: number) => {
      if (!isSearchable || index < 0 || index >= records.length) {
        return;
      }

      const record = records[index];
      const nodeObject = buildNodeObject(record);
      onItemClick(nodeObject);
    },
    [isSearchable, onItemClick, records]
  );

  const handleOnChangeEvent = useCallback(
    debounce(async (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (!isSearchable) {
        return;
      }

      if (event.target.value?.length >= 3) {
        searchPerformedRef.current = true;

        const filter = `field:${captionFieldId}:like:${inputRef.current?.value.trim()}&filter=field:${globalFieldKey}:eq:true`;

        setLoading(true);
        try {
          const records = await getRecords(
            { formId, sortDirective: [`field:${captionFieldId}`], filter },
            true
          ).unwrap();
          setRecords(records ?? []);
        } catch (_) {
          setRecords([]);
        } finally {
          setLoading(false);
        }
      } else if (event.target.value?.length < 3 && records.length > 0) {
        setRecords([]);
        setSelectedIndex(-1);
      }
    }, 800),
    [isSearchable, records]
  );

  const handleOnBlur = (event: React.FocusEvent) => {
    const currentTarget = event.currentTarget;

    // allow browser to focus the next element
    requestAnimationFrame(() => {
      // if the next focused element is not a child of the current element, then trigger the onBlur event
      if (!currentTarget.contains(document.activeElement)) {
        onBlur();
      }
    });
  };

  return (
    <div className="bt-h-full bt-w-full" onKeyDown={handleOnKeyDownEvent} onBlur={handleOnBlur}>
      <textarea
        autoFocus
        ref={inputRef}
        onChange={handleOnChangeEvent}
        placeholder={placeholder}
        className="nopan nodrag custom-scroll bt-h-full bt-w-full bt-resize-none bt-content-center bt-rounded-md bt-text-center bt-outline-none"
      />
      {isSearchable && (
        <RecordsList
          loading={loading}
          records={records}
          captionFieldId={captionFieldId}
          selectedIndex={selectedIndex}
          searchPerformed={searchPerformedRef.current}
          onItemClick={handleOnItemClick}
        />
      )}
    </div>
  );
};

export default forwardRef(NodeTextArea);
