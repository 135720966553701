import {
  Button,
  Checkbox,
  CheckboxChangeEventType,
  DomElementAlignment,
  DomTargetPosition,
  OdinIcon,
  OdinIconSize,
  OdinIconType,
  OverlayPanel,
  TabView,
  TabViewPanel,
  TabViewRef,
} from '@myosh/odin-components';
import { memo, useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectShowControlEffectiveness,
  selectShowControlTypes,
  selectShowOnlyCriticalControls,
  setFiter,
} from '../../redux/slices/diagram';

function FilterOverlay() {
  const dispatch = useDispatch();

  const [isFilterOverlayOpen, setIsFilterOverlayOpen] = useState(false);

  const filterIconRef = useRef<HTMLDivElement>(null);
  const tabViewRef = useRef<TabViewRef>(null);

  const showOnlyCriticalControlsRef = useRef<boolean>(false);
  const showControlTypesRef = useRef<boolean>(false);
  const showControlEffectivenessRef = useRef<boolean>(false);

  const showOnlyCriticalControlsState = useSelector(selectShowOnlyCriticalControls);
  const showControlTypesState = useSelector(selectShowControlTypes);
  const showControlEffectivenessState = useSelector(selectShowControlEffectiveness);

  showOnlyCriticalControlsRef.current = showOnlyCriticalControlsState;
  showControlTypesRef.current = showControlTypesState;
  showControlEffectivenessRef.current = showControlEffectivenessState;

  const toggleFilterOverlay = useCallback(() => {
    setIsFilterOverlayOpen((isOpen) => !isOpen);
  }, []);

  const applyClickHandler = useCallback(() => {
    dispatch(
      setFiter({
        showOnlyCriticalControls: showOnlyCriticalControlsRef.current,
        showControlTypes: showControlTypesRef.current,
        showControlEffectiveness: showControlEffectivenessRef.current,
      })
    );

    closeFilterOverlay();
  }, []);

  const closeFilterOverlay = useCallback(() => {
    setIsFilterOverlayOpen(false);
  }, []);

  const handleShowOnlyCriticalControlsChange = useCallback((value: CheckboxChangeEventType | undefined) => {
    showOnlyCriticalControlsRef.current = value?.checked as boolean;
  }, []);

  const handleShowControlTypesChange = useCallback((value: CheckboxChangeEventType | undefined) => {
    showControlTypesRef.current = value?.checked as boolean;
  }, []);

  const handleShowControlEffectivenessChange = useCallback((value: CheckboxChangeEventType | undefined) => {
    showControlEffectivenessRef.current = value?.checked as boolean;
  }, []);

  return (
    <>
      <div className="bt-flex bt-items-center" ref={filterIconRef} onClick={toggleFilterOverlay}>
        <OdinIcon
          icon="Filter"
          type={OdinIconType.Line}
          size={OdinIconSize.Small}
          className="bt-cursor-pointer bt-rounded-md bt-text-primary-1 hover:bt-bg-primary-5"
        />
      </div>
      <OverlayPanel
        target={filterIconRef.current}
        visible={isFilterOverlayOpen}
        hidden={closeFilterOverlay}
        elementAlignment={DomElementAlignment.TopLeft}
        targetPosition={DomTargetPosition.BottomLeft}
        mountOnEnter={true}
        shouldCheckZIndex={true}
        shouldUseCreatePortal={false}
        shouldRemainOnScreen={true}
        className="bt-w-96 bt-min-w-min bt-max-w-lg bt-bg-mono-1 bt-p-4"
      >
        <div className="bt-max-h-fit bt-min-h-64">
          <div className="bt-absolute bt-right-1 bt-top-1 bt-cursor-pointer" onClick={closeFilterOverlay}>
            <OdinIcon icon="Close" />
          </div>
          <TabView
            styles={{
              tabStyles: `bt-mr-4 bt-cursor-pointer bt-text-gray-3 bt-hover:text-primary-1 bt-font-medium`,
              activeTabStyles: `bt-font-medium bt-text-base bt-text-primary-2 bt-border-b-2 bt-border-solid bt-border-primary-2`,
              inactiveTabStyles: 'bt-border-none',
            }}
            activeIndex={0}
            ref={tabViewRef}
          >
            <TabViewPanel header="Display">
              <div className="bt-flex bt-flex-col">
                <Checkbox
                  initialChecked={showOnlyCriticalControlsState}
                  name="showOnlyCriticalControls"
                  label="Show only Critical Controls"
                  onChange={handleShowOnlyCriticalControlsChange}
                />
                <Checkbox
                  initialChecked={showControlTypesState}
                  name="showControlTypes"
                  label="Show Control Types"
                  onChange={handleShowControlTypesChange}
                />
                <Checkbox
                  initialChecked={showControlEffectivenessState}
                  name="showControlEffectiveness"
                  label="Show Control Effectiveness"
                  onChange={handleShowControlEffectivenessChange}
                />
              </div>
            </TabViewPanel>
          </TabView>
        </div>
        <Button onClick={applyClickHandler}>Apply</Button>
      </OverlayPanel>
    </>
  );
}

export default memo(FilterOverlay);
