import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { BowtieRequestWithId } from '../api/@types/enhanced-bowtie-api.types';
import { BowtieSuggestPostApiArg, useBowtieSuggestPostMutation } from '../api/generated/bowtie-api';

/**
 * Custom hook to handle AI analysis for bowtie data.
 *
 * @param {Function} onAIAnalysisReady - Callback function to be called when AI analysis is ready. Receives the analysis text as an argument.
 *
 * @returns {Object} - An object containing:
 *   - `runAIAnalysis` (Function): Function to trigger the AI analysis.
 *   - `isLoading` (boolean): Indicates whether the AI analysis is currently loading.
 *
 * @example
 * const { runAIAnalysis, isLoading } = useAIAnalysis((text) => {
 *   console.log('AI Analysis Result:', text);
 * });
 *
 * // Trigger AI analysis
 * runAIAnalysis();
 */
export const useAIAnalysis = (onAIAnalysisReady: (text: string) => void, flowBowtieDto: BowtieRequestWithId) => {
  const [getSuggestion, getSuggestionResult] = useBowtieSuggestPostMutation();

  const handleBowtieAIAnalysisClick = useCallback(async () => {
    const payload = generateFlowSuggestPayload(flowBowtieDto);

    toast.promise(
      getSuggestion(payload)
        .unwrap()
        .then((data) => onAIAnalysisReady(data?.suggestion_text)),
      {
        pending: 'Running AI Analysis...',
        success: { render: 'AI Analysis Complete', autoClose: 1200, type: 'info' },
        error: 'AI Analysis Failed',
      }
    );
  }, [flowBowtieDto]);

  return {
    runAIAnalysis: handleBowtieAIAnalysisClick,
    isLoading: getSuggestionResult?.isLoading,
  };
};

// util
const generateFlowSuggestPayload = (bowtieData: BowtieRequestWithId, term?: string): BowtieSuggestPostApiArg => {
  return {
    bowtieCorrectionRequest: {
      bowtie: {
        ...bowtieData,
        term: term ?? '',
      },
      control_types: [''],
    },
  };
};
