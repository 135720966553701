import { LoginCallback, LogoutCallback } from '@myosh/myosh-login';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { AppPermissionsProvider } from '../../context/app-permissions.context';
import { ConfigurationProvider } from '../../context/configuration.context';
import { DiagramContextProvider } from '../../context/diagram.context';
import { UserPermissionsProvider } from '../../context/user-permissions.context';
import Configuration from '../../views/configuration';
import Main from '../../views/main';
import LoginPage from './login-page';
import NoMatchRoute from './no-match-route';
import PrivateRoute from './private-route';
import SuperuserRoute from './superuser-route';

function RoutesComponent() {
  return (
    <Routes>
      <Route path="/auth/cb/logout" element={<LogoutCallback />} />
      <Route path="/auth/cb/silent" element={<div>Silent renewal callback</div>} />
      <Route path="/auth/cb" element={<LoginCallback />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/" element={<PrivateRoute />}>
        <Route
          index
          element={
            <ConfigurationProvider>
              <UserPermissionsProvider>
                <DiagramContextProvider>
                  <AppPermissionsProvider>
                    <Main />
                  </AppPermissionsProvider>
                </DiagramContextProvider>
              </UserPermissionsProvider>
            </ConfigurationProvider>
          }
        />
        <Route path="configuration" element={<SuperuserRoute />}>
          <Route index element={<Configuration />} />
        </Route>
      </Route>
      <Route path="*" element={<NoMatchRoute />} />
    </Routes>
  );
}

export default React.memo(RoutesComponent);
