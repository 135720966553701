import { ReactFlowProvider } from '@xyflow/react';

import React, { useEffect, useRef } from 'react';
import DiagramLoading from '../components/diagram/container/diagram-loading.component';
import Diagram from '../components/diagram/diagram.component';
import Header from '../components/header/header.component';
import { generateNodesAndEdges } from '../helpers/node-edge-generator';
import useDiagramData from '../hooks/use-diagram-data';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { initState, selectDiagramDisabled } from '../redux/slices/diagram';

function Main() {
  const { loading, diagramData } = useDiagramData();
  const dispatch = useAppDispatch();
  const initedRef = useRef(false);
  const disableDiagram = useAppSelector(selectDiagramDisabled);

  useEffect(() => {
    if (!initedRef.current && !loading && diagramData) {
      initedRef.current = true;
      const { nodes, edges } = generateNodesAndEdges(diagramData);
      dispatch(initState({ ...diagramData, nodes, edges }));
    }
  }, [loading, diagramData]);

  return (
    <div className="bt-flex bt-flex-col" style={{ width: '100vw', height: '100vh' }}>
      {!diagramData && <DiagramLoading loading={loading} />}
      {diagramData && !loading && (
        <>
          <Header />
          <ReactFlowProvider>
            <div className="bt-relative bt-h-full bt-w-full">
              {disableDiagram && <div className="bt-absolute bt-z-[1] bt-h-full bt-w-full bt-bg-transparent" />}
              <Diagram />
            </div>
          </ReactFlowProvider>
        </>
      )}
    </div>
  );
}

export default React.memo(Main);
