import React, { useCallback, useRef } from 'react';
import { TransformedSimpleRecordDto } from '../../../../api/@types/enhanced-v4-api.types';
import LoadingControls from '../../../../components/common/loading-controls.component';
import { cn } from '../../../../helpers/util';
import GlobeIcon from '../icons/globe-icon.component';

interface RecordsListProps {
  loading: boolean;
  selectedIndex: number;
  records: Array<TransformedSimpleRecordDto>;
  captionFieldId: number;
  searchPerformed: boolean;
  onItemClick: (index: number) => void;
}

/**
 * A component that displays a list of records with search and selection functionality.
 *
 * @component
 * @param {Object} props - The component props
 * @param {boolean} props.loading - Indicates if the records are being loaded
 * @param {number} props.selectedIndex - The index of the currently selected record
 * @param {Array<Record>} props.records - Array of records to display
 * @param {string} props.captionFieldId - The field id to use as caption for each record
 * @param {boolean} props.searchPerformed - Indicates if a search has been performed
 * @param {(index: number) => void} props.onItemClick - Callback function triggered when a record is clicked
 *
 * @returns {JSX.Element} A list component that can show loading state, no results message,
 * or a scrollable list of selectable records
 *
 * @example
 * <RecordsList
 *   loading={false}
 *   selectedIndex={0}
 *   records={[{ id: 1, fields: { name: 'Record 1' }}]}
 *   captionFieldId="1234"
 *   searchPerformed={true}
 *   onItemClick={(index) => console.log(index)
 * />
 */
const RecordsList = ({
  loading,
  selectedIndex,
  records,
  captionFieldId,
  searchPerformed,
  onItemClick,
}: RecordsListProps): JSX.Element => {
  const hasSearchResults = !loading && records.length > 0;
  const hasNoSearchResults = !loading && records.length === 0 && searchPerformed;

  return (
    <div
      className={cn(
        'node-record-list bt-absolute bt-left-0 bt-flex bt-w-full bt-flex-col bt-items-center bt-bg-gray-5 bt-shadow-md',
        { '-bt-bottom-6 bt-min-h-6 bt-justify-center': loading || hasNoSearchResults },
        { 'custom-scroll -bt-bottom-[100px] bt-h-[100px] bt-overflow-y-auto': hasSearchResults }
      )}
    >
      {loading && <LoadingControls />}
      {hasNoSearchResults && "Press 'Enter' to create"}
      {hasSearchResults &&
        records.map((record, index) => (
          <ListItem
            key={record.id}
            index={index}
            isSelected={index === selectedIndex}
            label={record.fields?.[captionFieldId] as string}
            onClick={onItemClick}
          />
        ))}
    </div>
  );
};

export default RecordsList;

// The single list item component
interface ListItemProps {
  index: number;
  label: string;
  isSelected: boolean;
  onClick: (index: number) => void;
}

const ListItem = React.memo(function ListItem({ index, label, isSelected, onClick }: ListItemProps) {
  const itemRef = useRef<HTMLDivElement>(null);

  if (isSelected) {
    itemRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  }

  const handleOnClick = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();

      onClick(index);
    },
    [onClick]
  );

  return (
    <div
      ref={itemRef}
      className={cn(
        'bt-flex bt-w-full bt-items-center bt-justify-between bt-px-1 bt-py-1 hover:bt-cursor-pointer hover:bt-bg-gray-4',
        { 'bt-bg-gray-4': isSelected }
      )}
      onClick={handleOnClick}
      tabIndex={-1}
    >
      <span>{label}</span>
      <GlobeIcon className="bt-text-primary bt-h-3 bt-w-3 bt-leading-4" />
    </div>
  );
});
