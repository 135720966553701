import { ControlButton } from '@xyflow/react';
import { memo, useCallback } from 'react';
import { cn } from '../../../helpers/util';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { selectMoving, setMoving } from '../../../redux/slices/diagram';
import GraphIcon from './icons/graph-icon.component';

const MoveNodesControl = memo(function MoveNodesControl() {
  const isMoving = useAppSelector(selectMoving);
  const dispatch = useAppDispatch();

  const handleClick = useCallback(() => {
    dispatch(setMoving(!isMoving));
  }, [isMoving]);

  return (
    <ControlButton title="Toggle arrange nodes" className={cn({ 'bt-bg-gray-4': isMoving })} onClick={handleClick}>
      <GraphIcon />
    </ControlButton>
  );
});

export default memo(MoveNodesControl);
