import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Id, toast } from 'react-toastify';
import { useDeleteBowtieMutation } from '../api/enhanced/enhanced-bowtie-api';
import {
  useCreateAndStartBowtieRecordsCreationJobMutation,
  useGetBackgroundJobDetailsQuery,
} from '../api/enhanced/enhanced-v4-api';
import { BowtieDto } from '../api/generated/v4-api';
import { useDiagramContext } from '../context/diagram.context';
import { useAppDispatch } from '../redux/hooks';
import { setDiagramDisabled } from '../redux/slices/diagram';
import { JobStatus, setJobStatus, submitJob } from '../redux/slices/running-jobs';

/**
 * Custom hook for creating backend records from a Bowtie diagram.
 * Handles the creation process, status monitoring, and UI feedback.
 *
 * @param bowtieDto - Optional BowtieDto for flow diagram
 * @param mueLabel - Optional Material Unwanted Event label
 * @param mueRecordId - Optional Material Unwanted Event record ID
 *
 * @returns Object containing:
 * - handleClick: Function to trigger the backend records creation
 * - disabled: Boolean indicating if the creation process is in progress
 *
 * @remarks
 * This hook manages the entire lifecycle of creating backend records:
 * - Initiates the creation job
 * - Monitors job status through polling
 * - Handles success/failure scenarios
 * - Manages toast notifications
 * - Updates URL parameters on success
 * - Cleans up saved diagrams if necessary
 *
 * @example
 * ```typescript
 * const { handleClick, disabled } = useCreateBackendRecords(bowtieDto);
 * ```
 */
const useCreateBackendRecords = (bowtieDto?: BowtieDto, mueLabel?: string, mueRecordId?: number) => {
  const [jobId, setJobId] = useState<number>();

  const toastIdRef = useRef<Id>();
  const dispatch = useAppDispatch();
  const { isSavedDiagram } = useDiagramContext();
  const { search } = useLocation();
  const queryParamsRef = useRef(new URLSearchParams(search));

  const [postCreateRecordsJob] = useCreateAndStartBowtieRecordsCreationJobMutation();
  const [deleteBowtie] = useDeleteBowtieMutation();

  const { data: { status, jobDetails } = {}, isError } = useGetBackgroundJobDetailsQuery(
    { id: jobId! },
    {
      skip: !jobId,
      pollingInterval: jobId ? 5000 : 0, // 5 seconds polling interval
    }
  );

  useEffect(() => {
    if ('FINISHED' === status && jobDetails && toastIdRef.current) {
      dispatch(setDiagramDisabled(false));
      setJobId(undefined);

      const parsedJobDetails = JSON.parse(jobDetails);
      if (parsedJobDetails?.recordId) {
        const params = new URLSearchParams({ records: String(parsedJobDetails.recordId) });

        toast.update(toastIdRef.current, {
          render: 'Diagram created successfully',
          type: 'success',
          isLoading: false,
          autoClose: 2000,
        });

        dispatch(
          setJobStatus({
            id: jobId!,
            status: JobStatus.Success,
            url: `${window.location.origin}${window.location.pathname}?${params}`,
            completedDate: new Date().toISOString(),
          })
        );

        // if there is a saved diagram, remove it
        if (isSavedDiagram && queryParamsRef.current.has('diagramId')) {
          deleteBowtie({ bowtieId: queryParamsRef.current.get('diagramId')! });
        }

        setTimeout(
          () => window.location.replace(`${window.location.origin}${window.location.pathname}?${params}`),
          2500
        );
      }
    } else if (('STAGED' === status || isError) && toastIdRef.current) {
      toast.update(toastIdRef.current, {
        render: 'Unable to create diagram',
        type: 'error',
        isLoading: false,
        autoClose: 2000,
      });

      dispatch(
        setJobStatus({
          id: jobId!,
          status: JobStatus.Failed,
          completedDate: new Date().toISOString(),
        })
      );

      dispatch(setDiagramDisabled(false));

      setJobId(undefined);
    }
  }, [status, isError]);

  const handleClick = useCallback(async () => {
    if (!mueLabel && !mueRecordId) {
      toast.error('Please specify the Material Unwanted Event', { autoClose: 2000 });
      return;
    }

    toastIdRef.current = toast.loading('Creating backend records...');

    // post records creation job
    const payload = { bowtieDto: bowtieDto! };

    try {
      const { id } = (await postCreateRecordsJob(payload).unwrap()) ?? {};

      setJobId(id);
      if (id) {
        dispatch(submitJob({ name: bowtieDto?.riskScenario, id: id! }));

        dispatch(setDiagramDisabled(true));
      }
    } catch {
      toast.update(toastIdRef.current, {
        render: 'Unable to create diagram',
        type: 'error',
        isLoading: false,
        autoClose: 2000,
      });
    }
  }, [mueLabel, mueRecordId, bowtieDto]);

  return {
    handleClick,
    disabled: Boolean(jobId),
  };
};

export default useCreateBackendRecords;
