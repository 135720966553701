import { Login } from '@myosh/myosh-login';
import { LoginResult } from '@myosh/myosh-login/dist/types/components/login.component';
import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export default function LoginPage() {
  const navigate = useNavigate();

  const { search, state } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  // check if the username was passed in the original URL
  const { search: fromSearch } = state?.from ?? {};
  const fromSearchParams = useMemo(() => new URLSearchParams(fromSearch), [fromSearch]);

  const username =
    searchParams.get('userName') ||
    searchParams.get('username') ||
    fromSearchParams.get('userName') ||
    fromSearchParams.get('username') ||
    undefined;

  const onLoginSuccess = (results: LoginResult) => {
    if (results) {
      localStorage.setItem('idToken', results.idToken);
      localStorage.setItem('schema', results.selectedSchema);
    }

    let pathname = '/',
      searchParams;

    const redirectLocation = localStorage.getItem('redirectLocation');
    if (redirectLocation) {
      localStorage.removeItem('redirectLocation');
      const location = JSON.parse(redirectLocation);

      if (location.pathname) {
        pathname = location.pathname;
      }

      if (location.search) {
        searchParams = `?${new URLSearchParams(location.search).toString()}`;
      }
    }

    navigate({ pathname, search: searchParams });
  };

  return <Login onLoginSuccess={onLoginSuccess} username={username} />;
}
