import { Button, ToggleButton } from '@myosh/odin-components';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useGetGlobalPromptQuery, useUpdateGlobalPromptMutation } from '../api/enhanced/enhanced-bowtie-api';
import { useGetBowtieConfigurationQuery, useSetBowtieConfigurationMutation } from '../api/enhanced/enhanced-v4-api';
import MyoshBowtieLogo from '../assets/svg/myosh-bowtie-logo.svg';
import LoadingPreview from '../components/common/loading-preview';

type InputType = 'toggle' | 'textArea';

interface ConfigurationOptionType {
  label: string;
  name: string;
  value: boolean | string | undefined;
  type: InputType;
  placeholder?: string;
}

const configurationOptions: ConfigurationOptionType[] = [
  {
    label: 'AI Enabled',
    name: 'aiEnabled',
    value: false,
    type: 'toggle',
  },
  {
    label: 'Global Custom Instruction',
    name: 'globalCustomInstruction',
    value: '',
    type: 'textArea',
    placeholder: 'Provide extra detail about your situation to generate a more relevant response.',
  },
];

function Configuration() {
  const navigate = useNavigate();
  const location = useLocation();

  const {
    data: bowtieConfig,
    isFetching: isFetchingBowtieConfiguration,
    isError: isErrorBowtieConfiguration,
  } = useGetBowtieConfigurationQuery();
  const {
    data: globalPrompt,
    isFetching: isFetchingGlobalPrompt,
    isError: isErrorGlobalPrompt,
  } = useGetGlobalPromptQuery();
  const [setBowtieConfiguration] = useSetBowtieConfigurationMutation();
  const [setGlobalPrompt] = useUpdateGlobalPromptMutation();
  const [options, setOptions] = useState<ConfigurationOptionType[]>(configurationOptions);

  const handleClickCancel = () => {
    navigate(location.state?.from || '/', { replace: true });
  };

  const handleClickSave = async () => {
    try {
      const payload = {
        aiEnabled: !!options[0].value,
      };

      const [bowtieConfigurationRes, globalPromptRes] = await Promise.all([
        setBowtieConfiguration({ bowtieConfigDto: payload }).unwrap(),
        setGlobalPrompt({ globalPromptDto: { prompt: options[1].value as string } }).unwrap(),
      ]);

      if (!bowtieConfigurationRes.result || !globalPromptRes) {
        toast.error('Error saving configuration, please try again.');
      } else {
        toast.success('Configuration saved successfully');
        navigate(location.state?.from || '/', { replace: true });
      }
    } catch (error) {
      toast.error('Error saving configuration, please try again.');
    }
  };

  const handleInputChange = (name: string, value: boolean | string | undefined) => {
    const updatedOptions = structuredClone(options).map((item) => {
      if (item.name === name) {
        return { ...item, value: value };
      }
      return item;
    });
    setOptions(updatedOptions);
  };

  const generateInputField = (option: ConfigurationOptionType) => {
    switch (option.type) {
      case 'toggle':
        return <ToggleButton onChange={(value) => handleInputChange(option.name, value)} checked={!!option.value} />;
      case 'textArea':
        return (
          <textarea
            value={option.value as string}
            name={option.name}
            placeholder={option.placeholder}
            className="bt-flex bt-w-80 bt-resize-none bt-appearance-none bt-rounded bt-border bt-border-primary-1 bt-px-2 bt-py-1 bt-text-sm focus:bt-outline-none"
            cols={30}
            rows={5}
            onChange={(event) => handleInputChange(event.target.name, event.target.value)}
          />
        );
      default:
        break;
    }
  };

  useEffect(() => {
    if (!bowtieConfig || !globalPrompt) return;
    if (isErrorBowtieConfiguration || isErrorGlobalPrompt) {
      toast.error('Error fetching bowtie configuration, refresh the page to retry.');
      return;
    }
    const result: { [key: string]: boolean } = bowtieConfig || {};

    const updatedOptions = options.map((item) => {
      if (item.name === 'globalCustomInstruction') {
        return { ...item, value: globalPrompt?.prompt ?? item.value };
      }
      return { ...item, value: result[item.name] ?? item.value };
    });
    setOptions(updatedOptions);
  }, [bowtieConfig, globalPrompt, isErrorBowtieConfiguration, isErrorGlobalPrompt]);

  return (
    <div className="bt-flex bt-h-full bt-w-full bt-flex-col bt-items-center bt-gap-8 bt-px-16 bt-py-2">
      <div className="bt-flex bt-h-12 bt-w-full bt-gap-8 bt-align-bottom">
        <img className="bt-w-[90px]" src={MyoshBowtieLogo} alt="myosh-bowtie-logo" />
        <p className="bt-text-4xl bt-font-bold">Configuration</p>
      </div>

      <div className="bt-flex bt-h-[50%] bt-w-[35%] bt-flex-col bt-gap-4 bt-pb-8">
        {isFetchingBowtieConfiguration || isFetchingGlobalPrompt ? (
          <LoadingPreview message="" />
        ) : (
          <>
            {options.map((item) => {
              return (
                <div key={item.name} className="bt-flex bt-w-full bt-flex-row bt-justify-between">
                  <p className="bt-text-lg bt-font-semibold">{item.label}</p>
                  {generateInputField(item)}
                </div>
              );
            })}
            <div className="bt-ml-auto bt-mt-auto bt-flex bt-flex-row bt-gap-4">
              <Button variant="regular" type="text" onClick={handleClickCancel} style={{ maxWidth: '200px' }}>
                Cancel
              </Button>
              <Button variant="alternative" type="primary" onClick={handleClickSave} style={{ maxWidth: '200px' }}>
                Save
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Configuration;
