import { useDiagramContext } from '../context/diagram.context';
import useAIGenerated from './use-ai-generated';
import useRecord from './use-record';
import useSavedDiagram from './use-saved-diagram';

/**
 * Custom hook for managing diagram data state based on the data source (AI-generated or record or previously saved diagram).
 *
 * @returns An object containing:
 * - `loading` - Boolean indicating if diagram data is being fetched
 * - `diagramData` - The diagram data, either AI-generated or from record
 *
 * @example
 * ```typescript
 * const { loading, diagramData } = useDiagramData();
 * ```
 */
const useDiagramData = () => {
  const { isAIGenerated, isSavedDiagram } = useDiagramContext();

  // handles a record source
  const { isFetching: isRecordFetching, diagramData: recordDiagramData } = useRecord();
  // handles an AI-generated source
  const { isFetching: isAIFetching, diagramData: aiGeneratedDiagramData } = useAIGenerated();
  // handles a diagram only saved source
  const { isFetching: isSavedFetching, diagramData: savedDiagramData } = useSavedDiagram();

  const loading =
    (!isAIGenerated && !isSavedDiagram && isRecordFetching && !recordDiagramData) ||
    (isAIGenerated && isAIFetching && !aiGeneratedDiagramData) ||
    (isSavedDiagram && isSavedFetching && !savedDiagramData);

  let diagramData;
  if (isSavedDiagram) {
    diagramData = savedDiagramData;
  } else if (isAIGenerated) {
    diagramData = aiGeneratedDiagramData;
  } else {
    diagramData = recordDiagramData;
  }

  return {
    loading,
    diagramData,
  };
};

export default useDiagramData;
