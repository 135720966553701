import { Button, OdinIcon, OdinIconSize, OdinIconType, SplitButton } from '@myosh/odin-components';
import { memo, useMemo } from 'react';
import { BowtieDto as SaveBowtieDto } from '../../api/generated/bowtie-api';
import { BowtieDto } from '../../api/generated/v4-api';
import { useAppPermissionsContext } from '../../context/app-permissions.context';
import useCreateBackendRecords from '../../hooks/use-create-backend-records-button-action';
import useDeleteDiagram from '../../hooks/use-delete-diagram-button-action';
import useSaveDiagram from '../../hooks/use-save-diagram-button-action';

// save icon
const SaveIcon = memo(function SaveIcon() {
  return <OdinIcon icon="Save" type={OdinIconType.Line} size={OdinIconSize.ExtraSmall} className="bt-mr-2" />;
});
SaveIcon.displayName = 'SaveIcon';

interface CreateSplitButtonProps {
  bowtieDtoCreate?: BowtieDto;
  bowtieDtoSave?: SaveBowtieDto;
  mueLabel?: string;
  mueRecordId?: number;
}

const CreateSplitButton = ({ bowtieDtoCreate, bowtieDtoSave, mueLabel, mueRecordId }: CreateSplitButtonProps) => {
  const {
    loading: isSavingOrUpdating,
    label: saveOrUpdateButtonLabel,
    handleClick: handleSaveOrUpdateClick,
    diagramId,
  } = useSaveDiagram(bowtieDtoSave, mueLabel);

  const { handleClick: handleCreateBackedRecordsClick, disabled: isCreateBackedRecordsDisabled } =
    useCreateBackendRecords(bowtieDtoCreate, mueLabel, mueRecordId);

  const { loading: isDeleting, handleClick: handleDeleteClick } = useDeleteDiagram(diagramId);

  const { addNodePermission, hasUserInSchema } = useAppPermissionsContext();

  const canCreateBackendRecords = useMemo(() => {
    if (hasUserInSchema) {
      return (
        addNodePermission.main &&
        addNodePermission.controls &&
        addNodePermission.causes &&
        addNodePermission.consequences
      );
    }

    return false;
  }, [addNodePermission, hasUserInSchema]);

  const { splitButtonItems, canRenderSplitButton } = useMemo(() => {
    const splitButtonItems = [
      {
        label: 'Create Backend Records',
        command: handleCreateBackedRecordsClick,
        visible: canCreateBackendRecords,
        icon: <OdinIcon icon="AddBox" type={OdinIconType.Line} size={OdinIconSize.ExtraSmall} className="bt-mr-2" />,
      },
      {
        label: 'Delete',
        command: handleDeleteClick,
        visible: Boolean(diagramId),
        icon: (
          <OdinIcon icon="DeleteBin5" type={OdinIconType.Line} size={OdinIconSize.ExtraSmall} className="bt-mr-2" />
        ),
      },
    ];

    const canRenderSplitButton = splitButtonItems.reduce((sum, item) => (item.visible === true ? sum + 1 : sum), 0) > 0;

    return { splitButtonItems, canRenderSplitButton };
  }, [handleCreateBackedRecordsClick, handleDeleteClick, diagramId]);

  const disabled = isSavingOrUpdating || isCreateBackedRecordsDisabled || isDeleting;

  return canRenderSplitButton ? (
    <SplitButton
      label={saveOrUpdateButtonLabel}
      onClick={handleSaveOrUpdateClick}
      model={splitButtonItems}
      disabled={disabled}
      className="bt-min-w-40"
      icon={<SaveIcon />}
    />
  ) : (
    <Button onClick={handleSaveOrUpdateClick} disabled={disabled} type="primary" icon={<SaveIcon />}>
      {saveOrUpdateButtonLabel}
    </Button>
  );
};

export default memo(CreateSplitButton);
