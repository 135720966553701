import {
  DataSearchLocation,
  DropDownNoSelection,
  DropDownRef,
  DropDownResult,
  isDropDownNoSelection,
  JsonDataWrapper,
  OdinDataRetrieval,
  OdinDataRetrievalOptions,
  OdinDataSender,
  DropDown as OdinDropDown,
} from '@myosh/odin-components';
import { ActionSectionItem } from '@myosh/odin-components/dist/types/components/fields/drop-down/drop-down-action-section.component';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useGetBowtieByIdQuery, useLazyGetAllBowtiesQuery } from '../../api/enhanced/enhanced-bowtie-api';
import { useGetSimpleRecordQuery, useLazyGetSimpleRecordsQuery } from '../../api/enhanced/enhanced-v4-api';
import { useConfigurationContext } from '../../context/configuration.context';
import { useDiagramContext } from '../../context/diagram.context';
import { cn } from '../../helpers/util';
import './record-drop-down-checkbox.styles.css';

type DropDownValue = Record<string, unknown>;

const valueFieldKey = 'id';
const textFieldKey = 'displayText';

/**
 * A dropdown component for the header section that displays and manages record selection.
 *
 * @component
 * @description
 * Renders a dropdown menu that allows users to:
 * - Display currently selected record
 * - Search and select records from an API
 * - Open selected records in a new window
 *
 * The component integrates with URL parameters to maintain state and uses the OdinDropDown
 * component for the actual dropdown functionality.
 *
 * @example
 * ```tsx
 * <RecordDropDown />
 * ```
 *
 * @remarks
 * The component handles two main modes:
 * - Regular records mode (when `isSavedDiagram` is false)
 * - Saved diagrams mode (when `isSavedDiagram` is true)
 *
 * @returns {JSX.Element} A dropdown component wrapped in a fixed-width container
 */

const RecordDropDown = (): JSX.Element => {
  const { isNewDiagram, isSavedDiagram } = useDiagramContext();

  const [record, setRecord] = useState<DropDownValue>();
  const [showDraft, setShowDraft] = useState<boolean>(isSavedDiagram);

  const showDraftRef = useRef(isSavedDiagram);
  const dropdownRef = useRef<DropDownRef>(null);

  const actionItems: Array<ActionSectionItem> = useMemo(() => {
    return [{ value: 'draft', text: 'Show Draft', selected: isSavedDiagram }];
  }, [isSavedDiagram]);

  const {
    main: { formId, scenarioFieldId },
  } = useConfigurationContext();

  const { search } = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const hasRecordId = queryParams.has('records') || queryParams.has('recordId');
  const hasDiagramId = queryParams.has('diagramId');

  const [getRecords] = useLazyGetSimpleRecordsQuery();
  const [getAllBowties] = useLazyGetAllBowtiesQuery();

  const { data, isFetching } = useGetSimpleRecordQuery(
    { id: Number(queryParams.get('records') ?? queryParams.get('recordId')), params: {} },
    { skip: isNewDiagram || !hasRecordId || showDraft }
  );

  const { data: diagram, isFetching: isDiagramFetching } = useGetBowtieByIdQuery(
    { bowtieId: queryParams.get('diagramId')! },
    { skip: isNewDiagram || !hasDiagramId }
  );

  if (!isFetching && data && !record && !showDraft) {
    // For the value to be set on the drop down, it must be in the format of DropDownResult and have 'value' and 'text' properties
    setRecord({ ...data, value: data.id, text: data.fields?.[scenarioFieldId] });
  }

  if (!isDiagramFetching && diagram && !record && showDraft) {
    // For the value to be set on the drop down, it must be in the format of DropDownResult and have 'value' and 'text' properties
    setRecord({ ...diagram, value: diagram.id, text: diagram.risk });
  }

  useEffect(() => {
    // runs in an effect to ensure dropdown state is updated
    dropdownRef.current?.getDataOverwritePageCache();
  }, [showDraft]);

  const dataRetrieval: OdinDataRetrieval = useMemo(() => {
    return {
      getData: async (subscriber: OdinDataSender<JsonDataWrapper>, options?: OdinDataRetrievalOptions) => {
        if (options) {
          if (showDraftRef.current === true) {
            if (options.page === 1) {
              // load list of saved diagrams only
              const diagrams = await getAllBowties().unwrap();

              const data =
                diagrams
                  .map((diagram) => ({
                    ...diagram,
                    [valueFieldKey]: diagram.id,
                    [textFieldKey]: diagram.risk,
                  }))
                  .filter((diagram) => {
                    // workaround to filter out the data manually as the API does not support filtering
                    if (options.fieldFilters?.[textFieldKey]) {
                      return diagram[textFieldKey]
                        ?.toLocaleLowerCase()
                        .includes((options.fieldFilters[textFieldKey].value as string).toLowerCase());
                    }

                    return true;
                  }) ?? [];

              subscriber.sendData({ data });
            } else {
              subscriber.sendData();
            }
          } else {
            let filter;
            if (options.fieldFilters?.[textFieldKey]) {
              filter = `field:${scenarioFieldId}:like:${options.fieldFilters[textFieldKey].value}`;
            }

            const records = await getRecords({
              formId,
              page: options.page ?? 1,
              pageSize: options.pageSize ?? 50,
              sortDirective: [`field:${scenarioFieldId}`],
              filter,
            }).unwrap();

            const data =
              records.map((record) => ({ ...record, [textFieldKey]: record.fields?.[scenarioFieldId] })) ?? [];
            subscriber.sendData({ data });
          }
        } else {
          subscriber.sendData();
        }
      },
    };
  }, []);

  const handleOnChange = (value?: DropDownResult | Array<DropDownResult> | DropDownNoSelection) => {
    if (value && !isDropDownNoSelection(value) && !Array.isArray(value)) {
      if (showDraftRef.current) {
        const params = new URLSearchParams({ diagramId: String(value.value) });
        window.open(`${window.location.origin}${window.location.pathname}?${params}`);
      } else {
        const params = new URLSearchParams({ records: String(value.value) });
        window.open(`${window.location.origin}${window.location.pathname}?${params}`);
      }

      // this is a workaround as the drop down will set the selected value internally,
      // and we need to keep the previous value
      if (isNewDiagram) {
        // clear selection for new diagrams
        setRecord({ noSelectionValue: true });
      } else {
        // reset selection for existing diagrams
        setRecord({ ...record });
      }
    }
  };

  const handleChangeActionItem = useCallback((item: ActionSectionItem) => {
    if (item.value === 'draft') {
      showDraftRef.current = Boolean(item.selected);
      setShowDraft(Boolean(item.selected));
    }
  }, []);

  const selectorStyles = cn('bt-text-base bt-font-bold bt-leading-9', {
    "before:bt-content-['New_Diagram']": isNewDiagram,
  });

  return (
    <div className="bt-min-w-80 bt-max-w-80">
      <OdinDropDown
        ref={dropdownRef}
        valueField={valueFieldKey}
        textField={textFieldKey}
        value={record as unknown as DropDownResult | DropDownNoSelection}
        onChange={handleOnChange}
        data={dataRetrieval}
        filterLocation={DataSearchLocation.Api}
        allowClear={false}
        preloadData
        shouldUseCreatePortal
        className="bt-h-12 bt-rounded bt-bg-gray-5"
        selectorStyles={selectorStyles}
        actionItems={actionItems}
        onActionItemChanged={handleChangeActionItem}
      />
    </div>
  );
};

export default memo(RecordDropDown);
