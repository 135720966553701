import { useUser } from '@myosh/myosh-login';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { ThemeProvider } from '../../context/theme.context';

function PrivateRoute() {
  const { state } = useUser();
  const { auth: isAuthenticated, loginStatus } = state;

  const location = useLocation();
  const resolvedLocation = location.state?.from ?? location;

  if (isAuthenticated && loginStatus === 'done') {
    return (
      <ThemeProvider>
        <Outlet />
      </ThemeProvider>
    );
  } else if (!isAuthenticated) {
    localStorage.setItem('redirectLocation', JSON.stringify(resolvedLocation));
    return <Navigate to="/login" replace state={{ from: resolvedLocation }} />;
  }
  return null;
}

export default PrivateRoute;
