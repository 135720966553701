import { createContext, useCallback, useContext, useMemo } from 'react';
import { FormNameKey } from '../@types/common-data-types';
import { useDiagramContext } from './diagram.context';
import { useUserPermissionsContext } from './user-permissions.context';

const allAppPermissions = { main: true, causes: true, consequences: true, controls: true };

type AppPermissions = {
  hasUserInSchema: boolean;
  addNodePermission: Record<FormNameKey, boolean>;
  editNodePermission: Record<FormNameKey, boolean>;
  removeNodePermission: Record<FormNameKey, boolean>;

  resolvePermissionsByNodeType: (type?: string) => {
    addNodePermission: boolean;
    editNodePermission: boolean;
    removeNodePermission: boolean;
  };
};

type AppPermissionsContextProps = React.PropsWithChildren;

const AppPermissionsContext = createContext<AppPermissions | undefined>(undefined);

export const AppPermissionsProvider = ({ children }: AppPermissionsContextProps) => {
  const { isAIGenerated, isNewDiagram, isSavedDiagram } = useDiagramContext();
  const {
    createRecord: apiPermissionCreateRecord,
    editRecord: apiPermissionEditRecord,
    deleteRecord: apiPermissionsDeleteRecord,
    isSuperUser,
  } = useUserPermissionsContext();

  const { addNodePermission, editNodePermission, removeNodePermission, hasUserInSchema } = useMemo(() => {
    if (!isAIGenerated && !isNewDiagram && !isSavedDiagram) {
      // we have a record id, therefore the user permissions should be used
      return {
        addNodePermission: apiPermissionCreateRecord,
        editNodePermission: apiPermissionEditRecord,
        removeNodePermission: apiPermissionsDeleteRecord,
        hasUserInSchema: isSuperUser === false,
      };
    } else {
      // we don't have a record id, therefore the user can add, edit and remove nodes
      return {
        addNodePermission: allAppPermissions,
        editNodePermission: allAppPermissions,
        removeNodePermission: allAppPermissions,
        hasUserInSchema: isSuperUser === false,
      };
    }
  }, []);

  const resolvePermissionsByNodeType = useCallback(
    (type?: string) => {
      if ('mue-node' === type || 'hazard-node' === type) {
        return {
          addNodePermission: addNodePermission.main,
          editNodePermission: editNodePermission.main,
          removeNodePermission: removeNodePermission.main,
        };
      } else if ('cause-node' === type) {
        return {
          addNodePermission: addNodePermission.causes,
          editNodePermission: editNodePermission.causes,
          removeNodePermission: removeNodePermission.causes,
        };
      } else if ('consequence-node' === type) {
        return {
          addNodePermission: addNodePermission.consequences,
          editNodePermission: editNodePermission.consequences,
          removeNodePermission: removeNodePermission.consequences,
        };
      } else if ('preventative-control-node' === type || 'mitigating-control-node' === type) {
        return {
          addNodePermission: addNodePermission.controls,
          editNodePermission: editNodePermission.controls,
          removeNodePermission: removeNodePermission.controls,
        };
      } else {
        return { addNodePermission: false, editNodePermission: false, removeNodePermission: false };
      }
    },
    [addNodePermission, editNodePermission, removeNodePermission]
  );

  return (
    <AppPermissionsContext.Provider
      value={{
        hasUserInSchema,
        addNodePermission,
        editNodePermission,
        removeNodePermission,
        resolvePermissionsByNodeType,
      }}
    >
      {children}
    </AppPermissionsContext.Provider>
  );
};

// context consumer hook
export const useAppPermissionsContext = () => {
  const context = useContext(AppPermissionsContext);

  if (context === undefined) {
    throw new Error('useAppPermissionsContext must be used within a AppPermissionsProvider');
  }

  return context;
};
