import LoadingControls from './loading-controls.component';

interface LoadingPreviewProps {
  message: string;
}

const LoadingPreview = ({ message }: LoadingPreviewProps) => {
  return (
    <div className="bt-mt-4 bt-text-gray-1">
      <LoadingControls className="bt-mb-2" />
      {message}
    </div>
  );
};

export default LoadingPreview;
