import { Button } from '@myosh/odin-components';
import { memo, useCallback } from 'react';
import { ToastContentProps } from 'react-toastify';

interface ConfirmActionToastProps extends ToastContentProps {
  message: string;
  confirmActionLabel: string;
  onConfirmActionClick: () => void;
}

/**
 * A toast component that displays a confirmation message with action buttons.
 *
 * @component
 * @param {Object} props - The component props
 * @param {string} props.message - The confirmation message to display
 * @param {string} props.confirmActionLabel - The label for the confirm action button
 * @param {() => void} props.onConfirmActionClick - Callback function triggered when confirm action is clicked
 * @param {() => void} props.closeToast - Callback function to close the toast
 *
 * @example
 * ```tsx
 * <ConfirmActionToast
 *   message="Are you sure you want to proceed?"
 *   confirmActionLabel="Confirm"
 *   onConfirmActionClick={() => handleConfirm()}
 *   closeToast={() => closeToast()}
 * />
 * ```
 *
 * @returns A toast component with a message and action buttons
 */
const ConfirmActionToast = ({
  message,
  confirmActionLabel,
  onConfirmActionClick,
  closeToast,
}: ConfirmActionToastProps) => {
  const handleUnlink = useCallback(() => {
    onConfirmActionClick();
    closeToast();
  }, [onConfirmActionClick, closeToast]);

  return (
    <div className="bt-flex bt-flex-col">
      <div className="bt-whitespace-pre-line bt-py-4">{message}</div>
      <div className="bt-flex bt-flex-row-reverse bt-gap-2">
        <Button type="primary" onClick={handleUnlink}>
          {confirmActionLabel}
        </Button>
        <Button type="text" onClick={closeToast}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default memo(ConfirmActionToast);
