import { emptyBowtieApi as api } from '../base/bowtie-api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getGlobalPrompt: build.query<GetGlobalPromptApiResponse, GetGlobalPromptApiArg>({
      query: () => ({ url: `/prompt/global` }),
    }),
    updateGlobalPrompt: build.mutation<UpdateGlobalPromptApiResponse, UpdateGlobalPromptApiArg>({
      query: (queryArg) => ({ url: `/prompt/global`, method: 'PUT', body: queryArg.globalPromptDto }),
    }),
    getAllBowties: build.query<GetAllBowtiesApiResponse, GetAllBowtiesApiArg>({
      query: () => ({ url: `/record` }),
    }),
    saveBowtie: build.mutation<SaveBowtieApiResponse, SaveBowtieApiArg>({
      query: (queryArg) => ({ url: `/record`, method: 'POST', body: queryArg.bowtieDto }),
    }),
    bowtieSuggestPost: build.mutation<BowtieSuggestPostApiResponse, BowtieSuggestPostApiArg>({
      query: (queryArg) => ({
        url: `/ai/suggest`,
        method: 'POST',
        body: queryArg.bowtieCorrectionRequest,
        params: {
          llmProvider: queryArg.llmProvider,
        },
      }),
    }),
    bowtieHazardMueMaterialUnwantedEventsPost: build.mutation<
      BowtieHazardMueMaterialUnwantedEventsPostApiResponse,
      BowtieHazardMueMaterialUnwantedEventsPostApiArg
    >({
      query: (queryArg) => ({
        url: `/ai/material-unwanted-events`,
        method: 'POST',
        body: queryArg.hazardMueRequest,
        params: {
          llmProvider: queryArg.llmProvider,
        },
      }),
    }),
    bowtieFactorsPost: build.query<BowtieFactorsPostApiResponse, BowtieFactorsPostApiArg>({
      query: (queryArg) => ({
        url: `/ai/factors`,
        method: 'POST',
        body: queryArg.factorRequest,
        params: {
          llmProvider: queryArg.llmProvider,
        },
      }),
    }),
    bowtieConvertImagePost: build.mutation<BowtieConvertImagePostApiResponse, BowtieConvertImagePostApiArg>({
      query: (queryArg) => ({ url: `/ai/convert-image/start`, method: 'POST', body: queryArg.body }),
    }),
    getBowtieById: build.query<GetBowtieByIdApiResponse, GetBowtieByIdApiArg>({
      query: (queryArg) => ({ url: `/record/${queryArg.bowtieId}` }),
    }),
    deleteBowtie: build.mutation<DeleteBowtieApiResponse, DeleteBowtieApiArg>({
      query: (queryArg) => ({ url: `/record/${queryArg.bowtieId}`, method: 'DELETE' }),
    }),
    updateBowtie: build.mutation<UpdateBowtieApiResponse, UpdateBowtieApiArg>({
      query: (queryArg) => ({ url: `/record/${queryArg.bowtieId}`, method: 'PATCH', body: queryArg.bowtieDto }),
    }),
    bowtieConvertImageJobIdGet: build.query<BowtieConvertImageJobIdGetApiResponse, BowtieConvertImageJobIdGetApiArg>({
      query: (queryArg) => ({ url: `/ai/convert-image/${queryArg.jobId}` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as bowtieApi };
export type GetGlobalPromptApiResponse = /** status 200 OK */ GlobalPromptDto;
export type GetGlobalPromptApiArg = void;
export type UpdateGlobalPromptApiResponse = /** status 200 OK */ boolean;
export type UpdateGlobalPromptApiArg = {
  globalPromptDto: GlobalPromptDto;
};
export type GetAllBowtiesApiResponse = /** status 200 OK */ Bowtie[];
export type GetAllBowtiesApiArg = void;
export type SaveBowtieApiResponse = /** status 200 OK */ string;
export type SaveBowtieApiArg = {
  bowtieDto: BowtieDto;
};
export type BowtieSuggestPostApiResponse = /** status 200 OK */ SuggestResponse;
export type BowtieSuggestPostApiArg = {
  llmProvider?: 'OPENAI' | 'XAI' | 'AMAZON' | 'DEEPSEEK';
  bowtieCorrectionRequest: BowtieCorrectionRequest;
};
export type BowtieHazardMueMaterialUnwantedEventsPostApiResponse = /** status 200 OK */ HazardMuePair[];
export type BowtieHazardMueMaterialUnwantedEventsPostApiArg = {
  llmProvider?: 'OPENAI' | 'XAI' | 'AMAZON' | 'DEEPSEEK';
  hazardMueRequest: HazardMueRequest;
};
export type BowtieFactorsPostApiResponse = /** status 200 OK */ Detail;
export type BowtieFactorsPostApiArg = {
  llmProvider?: 'OPENAI' | 'XAI' | 'AMAZON' | 'DEEPSEEK';
  factorRequest: FactorRequest;
};
export type BowtieConvertImagePostApiResponse = /** status 200 OK */ string;
export type BowtieConvertImagePostApiArg = {
  body: {
    image_file: Blob;
    control_types?: string;
    extra_prompt?: string;
  };
};
export type GetBowtieByIdApiResponse = /** status 200 OK */ Bowtie;
export type GetBowtieByIdApiArg = {
  bowtieId: string;
};
export type DeleteBowtieApiResponse = /** status 200 OK */ boolean;
export type DeleteBowtieApiArg = {
  bowtieId: string;
};
export type UpdateBowtieApiResponse = /** status 200 OK */ boolean;
export type UpdateBowtieApiArg = {
  bowtieId: string;
  bowtieDto: BowtieDto;
};
export type BowtieConvertImageJobIdGetApiResponse = /** status 200 OK */ BackgroundJobConvertImageResponse;
export type BowtieConvertImageJobIdGetApiArg = {
  jobId: string;
};
export type GlobalPromptDto = {
  prompt?: string;
};
export type Control = {
  name?: string;
  category?: 'Hard' | 'Soft';
  type?: 'Preventative' | 'Mitigating';
  isGlobal?: boolean;
  isCritical?: boolean;
  isInitial?: boolean;
  controlType?: string;
};
export type Factor = {
  name?: string;
  controls?: Control[];
};
export type Bowtie = {
  username?: string;
  term?: string;
  hazard?: string;
  risk?: string;
  causes?: Factor[];
  consequences?: Factor[];
  id?: string;
};
export type ControlDto = {
  name?: string;
  category?: 'Hard' | 'Soft';
  type?: 'Preventative' | 'Mitigating';
  isGlobal?: boolean;
  isCritical?: boolean;
  isInitial?: boolean;
  controlType?: string;
};
export type FactorDto = {
  name?: string;
  controls?: ControlDto[];
};
export type BowtieDto = {
  term?: string;
  hazard?: string;
  risk?: string;
  causes?: FactorDto[];
  consequences?: FactorDto[];
};
export type SuggestResponse = {
  /** Suggested text version to correct the original Bowtie Diagram with what to add, remove, or modify */
  suggestion_text: string;
};
export type ControlRequestWithId = {
  /** Control short name */
  name: string;
  /** Control category (Hard or Soft) */
  category: 'Hard' | 'Soft';
  /** Control type where values are only Preventative or Mitigating, where preventive control is a measure or action that is designed to stop a risk or hazard from occurring in the first place and mitigating control (also referred to as a mitigation or a barrier) is a specific action, procedure, or mechanism that reduces the impact of an event after it has occurred. */
  type: 'Preventative' | 'Mitigating';
  /** Indicates whether this control is global */
  isGlobal: boolean;
  /** Indicates whether this control is critical */
  isCritical: boolean;
  /** Indicates whether this control is required to be initially implemented */
  isInitial: boolean;
  controlType?: string;
  id: string;
};
export type CauseRequestWithId = {
  /** Cause short name of a material unwanted event */
  name: string;
  controls: ControlRequestWithId[];
  id: string;
};
export type ConsequenceRequestWithId = {
  /** Consequence short name of a material unwanted event */
  name: string;
  controls: ControlRequestWithId[];
  id: string;
};
export type BowtieCorrectionRequest = {
  bowtie: {
    hazard: string;
    risk: string;
    causes: CauseRequestWithId[];
    consequences: ConsequenceRequestWithId[];
    term: string;
  };
  control_types?: string[];
  extra_prompt?: string;
};
export type HazardMuePair = {
  /** The operation, activity, or material that has one or more material unwanted event that could happen */
  hazard: string;
  /** List of material unwanted event associated with the hazard */
  material_unwanted_event: string[];
};
export type HazardMueRequest = {
  term: string;
  extra_prompt?: string;
  hazard?: string;
};
export type ControlWithId = {
  /** Control short name */
  name: string;
  /** Control category (Hard or Soft) */
  category: 'Hard' | 'Soft';
  /** Control type (Preventative or Mitigating) */
  type: 'Preventative' | 'Mitigating';
  /** Indicates whether this control is global */
  isGlobal: boolean;
  /** Indicates whether this control is critical */
  isCritical: boolean;
  /** Indicates whether this control is required to be initially implemented */
  isInitial: boolean;
  /** Control Subtype */
  controlType: string;
  /** Control ID */
  id: string;
  /** Indicates whether this control is a control issue */
  isControlIssues: boolean;
};
export type FactorId = {
  /** Factor ID */
  id: string;
  /** Factor name */
  name: string;
  /** Factor type (Cause or Consequence) */
  type: 'Cause' | 'Consequence';
  /** List of controls related to a factor */
  controls: ControlWithId[];
  /** Factor position */
  position: number;
};
export type Detail = {
  /** The source of a material unwanted event, whether it be an operation, activity, or material. */
  hazard: string;
  /** List of causes of a material unwanted event */
  causes: FactorId[];
  /** List of consequences of a material unwanted event */
  consequences: FactorId[];
};
export type FactorRequest = {
  term: string;
  selected_MUE: string;
  cause_qty?: number;
  consequence_qty?: number;
  preventative_qty?: number;
  mitigation_qty?: number;
  control_types?: string[];
  hazard?: string;
  extra_prompt?: string;
};
export type BackgroundJobConvertImageResponse = {
  /** The state of the background job */
  state: 'PENDING' | 'STARTED' | 'RETRY' | 'FAILURE' | 'SUCCESS';
  /** The status information detail of the background job */
  status: string;
  result: {
    hazard: string;
    /** The Risk of the term to find the cause and consequence */
    risk: string;
    /** List of causes of a material unwanted event */
    causes: FactorId[];
    /** List of consequences of a material unwanted event */
    consequences: FactorId[];
  };
};
export const {
  useGetGlobalPromptQuery,
  useLazyGetGlobalPromptQuery,
  useUpdateGlobalPromptMutation,
  useGetAllBowtiesQuery,
  useLazyGetAllBowtiesQuery,
  useSaveBowtieMutation,
  useBowtieSuggestPostMutation,
  useBowtieHazardMueMaterialUnwantedEventsPostMutation,
  useBowtieFactorsPostQuery,
  useLazyBowtieFactorsPostQuery,
  useBowtieConvertImagePostMutation,
  useGetBowtieByIdQuery,
  useLazyGetBowtieByIdQuery,
  useDeleteBowtieMutation,
  useUpdateBowtieMutation,
  useBowtieConvertImageJobIdGetQuery,
  useLazyBowtieConvertImageJobIdGetQuery,
} = injectedRtkApi;
