import { memo } from 'react';
import { IconProps } from '../../../../@types/common';
import { cn } from '../../../../helpers/util';

/**
 * A React functional component that renders an SVG icon representing an "unlink" action.
 *
 * @component
 * @param {Object} props - The properties object.
 * @param {number} [props.width=20] - The width of the SVG icon. Defaults to 20.
 * @param {number} [props.height=20] - The height of the SVG icon. Defaults to 20.
 * @param {string} [props.className] - Additional CSS classes to apply to the SVG element.
 *
 * @returns {JSX.Element} The rendered SVG icon.
 */
function UnlinkIcon({ width = 20, height = 20, className }: IconProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16">
      <svg className={cn(className, 'bt-fill-current')}>
        <circle cx="8" cy="8" r="7" strokeWidth="0.75" />
      </svg>
      <svg className="bt-fill-current">
        <path
          d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </svg>
  );
}

export default memo(UnlinkIcon);
