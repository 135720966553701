import { memo } from 'react';
import { IconProps } from '../../../../@types/common';
import { cn } from '../../../../helpers/util';

/**
 * A graph icon component.
 *
 * @component
 * @param {Object} props - The component props
 * @param {number} [props.width=20] - The width of the icon in pixels
 * @param {number} [props.height=20] - The height of the icon in pixels
 * @param {string} [props.className] - Additional CSS class names to apply to the icon
 * @returns {JSX.Element} An SVG icon of an upward-pointing arrow in a circle
 *
 * @example
 * ```tsx
 * <ArrowDownIcon width={24} height={24} className="custom-class" />
 * ```
 */
function GraphIcon({ width = 24, height = 24, className }: IconProps): JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      className={cn('bt-stroke-gray-1 bt-text-gray-1', className)}
      strokeWidth="0.1"
    >
      <path d="M18 3C19.6569 3 21 4.34315 21 6C21 7.65685 19.6569 9 18 9H15C13.6941 9 12.5831 8.16562 12.171 7.0009L11 7C9.9 7 9 7.9 9 9L9.0009 9.17102C10.1656 9.58312 11 10.6941 11 12C11 13.3059 10.1656 14.4169 9.0009 14.829L9 15C9 16.1 9.9 17 11 17L12.1707 17.0001C12.5825 15.8349 13.6937 15 15 15H18C19.6569 15 21 16.3431 21 18C21 19.6569 19.6569 21 18 21H15C13.6941 21 12.5831 20.1656 12.171 19.0009L11 19C8.79 19 7 17.21 7 15H5C3.34315 15 2 13.6569 2 12C2 10.3431 3.34315 9 5 9H7C7 6.79086 8.79086 5 11 5L12.1707 5.00009C12.5825 3.83485 13.6937 3 15 3H18ZM18 17H15C14.4477 17 14 17.4477 14 18C14 18.5523 14.4477 19 15 19H18C18.5523 19 19 18.5523 19 18C19 17.4477 18.5523 17 18 17ZM8 11H5C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13H8C8.55228 13 9 12.5523 9 12C9 11.4477 8.55228 11 8 11ZM18 5H15C14.4477 5 14 5.44772 14 6C14 6.55228 14.4477 7 15 7H18C18.5523 7 19 6.55228 19 6C19 5.44772 18.5523 5 18 5Z"></path>
    </svg>
  );
}

export default memo(GraphIcon);
