import { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ControlDiagramNode, DiagramConfiguration } from '../@types/diagram';
import { BowtieResponse } from '../api/@types/enhanced-bowtie-api.types';
import { useBowtieFactorsPostQuery } from '../api/enhanced/enhanced-bowtie-api';
import { useGetSimpleOptionsForFieldQuery } from '../api/enhanced/enhanced-v4-api';
import { Detail } from '../api/generated/bowtie-api';
import { useConfigurationContext } from '../context/configuration.context';
import { CriticalControlEnum } from '../helpers/node-util';

/**
 * Hook for handling AI-generated diagram data based on URL query parameters.
 *
 * @remarks
 * This hook processes two types of AI-generated content:
 * 1. Direct AI generation using term, hazard, and MUE parameters
 * 2. Imported AI diagrams using the import parameter
 *
 * @returns An object containing:
 * - `isFetching` - Boolean indicating if data is being fetched or processed
 * - `diagramData` - The generated diagram configuration data
 *
 * @example
 * ```tsx
 * const { isFetching, diagramData } = useAIGenerated();
 * ```
 */
const useAIGenerated = () => {
  const diagramDataRef = useRef<DiagramConfiguration>();

  const { search } = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const isAIGenerated = queryParams.has('term') && queryParams.has('hazard') && queryParams.has('mue');
  const isAIImported = queryParams.has('import');

  const [isAIImportedLoading, setIsAIImportedLoading] = useState(isAIImported);

  const {
    controls: { formId, controlTypeFieldId },
  } = useConfigurationContext();

  const { data: controlTypes, isFetching: isControlTypesFetching } = useGetSimpleOptionsForFieldQuery(
    {
      fieldId: controlTypeFieldId,
      formId,
      limit: -1,
    },
    {
      skip: !isAIGenerated || isAIImported || !formId || !controlTypeFieldId,
    }
  );

  const { data, isFetching: isFactorsFetching } = useBowtieFactorsPostQuery(
    {
      factorRequest: {
        term: queryParams.get('term')!,
        hazard: queryParams.get('hazard')!,
        selected_MUE: queryParams.get('mue')!,
        extra_prompt: queryParams.get('prompt') ?? undefined,
        control_types: controlTypes?.map((controlType) => controlType.caption!) ?? [''],
      },
    },
    { skip: !isAIGenerated || isControlTypesFetching || isAIImported }
  );

  if (isAIGenerated && data && !isFactorsFetching && !diagramDataRef.current) {
    diagramDataRef.current = buildAIGeneratedDiagramData(data, queryParams.get('mue')!);
  } else if (isAIImported && !diagramDataRef.current) {
    const diagramId = queryParams.get('import');
    const importedDiagram = localStorage.getItem(`importedDiagram-${diagramId}`);
    if (importedDiagram) {
      diagramDataRef.current = buildAIImportedDiagramData(importedDiagram);
      setIsAIImportedLoading(false);
    }
  }

  useEffect(() => {
    // removes the imported diagram from local storage after it has been processed
    if (diagramDataRef.current && isAIImported) {
      const diagramId = queryParams.get('import');
      if (diagramId) {
        localStorage.removeItem(`importedDiagram-${diagramId}`);
      }
    }
  }, []);

  return {
    isFetching: isControlTypesFetching || isFactorsFetching || isAIImportedLoading,
    diagramData: diagramDataRef.current,
  };
};

export default useAIGenerated;

/**
 * Builds a diagram configuration from AI generated data.
 *
 * @param data - The detailed data containing hazard, causes, and consequences information
 * @param mue - The MUE (Main Undesired Event) label string
 * @returns A DiagramConfiguration object containing the structured bow-tie diagram data
 */
const buildAIGeneratedDiagramData = (data: Detail, mue: string): DiagramConfiguration => {
  const diagramData: DiagramConfiguration = {
    mue: { id: crypto.randomUUID() },
    causes: [],
    consequences: [],
  };

  // mue
  diagramData.mue = {
    ...diagramData.mue,
    label: mue,
  };

  // hazard
  diagramData.hazard = {
    id: crypto.randomUUID(),
    label: data.hazard,
  };

  // causes and preventative controls
  diagramData.causes = data.causes.map((cause) => {
    const controls = cause.controls.map((control) => {
      const criticalOrNonCritical = control.isCritical ? CriticalControlEnum.CRITICAL : undefined;
      return {
        id: control.id,
        label: control.name,
        global: control.isGlobal,
        criticalControlType: criticalOrNonCritical,
        _metadata: {
          aiData: { ...control },
        },
      } as ControlDiagramNode;
    });

    return {
      id: cause.id,
      label: cause.name,
      controls,
    };
  });

  // consequences and mitigating controls
  diagramData.consequences = data.consequences.map((consequence) => {
    const controls = consequence.controls.map((control) => {
      const criticalOrNonCritical = control.isCritical ? CriticalControlEnum.CRITICAL : undefined;
      return {
        id: control.id,
        label: control.name,
        global: control.isGlobal,
        criticalControlType: criticalOrNonCritical,
        _metadata: {
          aiData: { ...control },
        },
      } as ControlDiagramNode;
    });

    return {
      id: consequence.id,
      label: consequence.name,
      controls,
    };
  });

  return diagramData;
};

/**
 * Builds a diagram configuration from imported AI-generated diagram data.
 * @param importedDiagram - JSON string containing the bowtie diagram data
 * @returns {DiagramConfiguration} A configuration object for the diagram containing MUE, causes, consequences and their associated controls
 */
const buildAIImportedDiagramData = (importedDiagram: string): DiagramConfiguration => {
  const data = JSON.parse(importedDiagram) as BowtieResponse;

  const diagramData: DiagramConfiguration = {
    mue: { id: crypto.randomUUID() },
    causes: [],
    consequences: [],
  };

  // mue
  diagramData.mue = {
    ...diagramData.mue,
    label: data.risk,
  };

  // hazard
  diagramData.hazard = {
    id: crypto.randomUUID(),
    label: data.hazard,
  };

  // causes and preventative controls
  diagramData.causes = data.causes.map((cause) => {
    const controls = cause.controls.map((control) => {
      const criticalOrNonCritical = control.isCritical ? CriticalControlEnum.CRITICAL : undefined;
      return {
        id: control.id,
        label: control.name,
        global: control.isGlobal,
        criticalControlType: criticalOrNonCritical,
        _metadata: {
          aiData: { ...control },
        },
      } as ControlDiagramNode;
    });

    return {
      id: cause.id,
      label: cause.name,
      controls,
    };
  });

  // consequences and mitigating controls
  diagramData.consequences = data.consequences.map((consequence) => {
    const controls = consequence.controls.map((control) => {
      const criticalOrNonCritical = control.isCritical ? CriticalControlEnum.CRITICAL : undefined;
      return {
        id: control.id,
        label: control.name,
        global: control.isGlobal,
        criticalControlType: criticalOrNonCritical,
        _metadata: {
          aiData: { ...control },
        },
      } as ControlDiagramNode;
    });

    return {
      id: consequence.id,
      label: consequence.name,
      controls,
    };
  });

  return diagramData;
};
